import {ACCESS_CONTROL_ENTITIES} from "./access_control_entities.js";

export function unloadOneType(entityName, dispatch, sessions, filterFunc) {
  const onceOnly = ACCESS_CONTROL_ENTITIES.includes(entityName);

  for (let key of Object.keys(sessions)) {
    dispatch({
      type: `${entityName}/remove`,
      session: sessions[key],
      payload: filterFunc
    });

    if (onceOnly) {
      break;
    }
  }
}
