import Aqumen from "@aqumen/sdk";
import React from "react";

import {ConfirmableButton} from "@/library/controls/confirmable_button.jsx";

export function ControlServerActionButton({
    action, confirming, controlServer,
    className = "",
    onClick = () => {},
    setConfirming = () => {}
  }) {

  const disabled = controlServer.archivedAt || (confirming && confirming !== action);

  const handleClick = async (event) => {
    if (event.confirmed) {
      const serviceCall = Aqumen.ControlServer[action](controlServer.session, controlServer);
      await onClick(event);
      return serviceCall;
    }

    await onClick(event);
  };

  const currentClassName = `action-button control-server-action-button ${className}`;
  console.debug("CONTROL SERVER BUTTON", {confirming, action})
  return (
    <ConfirmableButton
        className={currentClassName}
        confirming={confirming === action}
        disabled={disabled}
        messageId={`operations.controlServers.controlServer.actions.${action}`}
        onClick={handleClick}
        setConfirming={(on) => setConfirming((on) ? action : null)}
    />
  );
}
