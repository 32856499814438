// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInOpacity4 {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes fadeInOpacity7 {
  0% {
    color: var(--color-elevated-success);
    fill: var(--color-elevated-success);
    stroke: var(--color-elevated-success);
  }
  50% {
    color: var(--color-elevated-text);
    fill: var(--color-elevated-draw);
    stroke: var(--color-elevated-draw);
  }
}
@keyframes slide-right {
  from {
    margin-left: 0;
    margin-top: 3rem;
  }
  to {
    margin-left: 13rem;
    margin-top: 0rem;
  }
}
@keyframes slide-left {
  from {
    margin-left: 13rem;
    margin-top: 0rem;
  }
  to {
    margin-left: 0;
    margin-top: 3rem;
  }
}
@keyframes product-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes product-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blur-in {
  from {
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
  }
  to {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/style/keyframes.scss"],"names":[],"mappings":"AAAA;EACE;IACE,YAAA;EACF;EACA;IACE,UAAA;EACF;EACA;IACE,YAAA;EACF;AACF;AAEA;EACE;IACE,oCAAA;IACA,mCAAA;IACA,qCAAA;EAAF;EAEA;IACE,iCAAA;IACA,gCAAA;IACA,kCAAA;EAAF;AACF;AAGA;EACE;IACE,cAAA;IACA,gBAAA;EADF;EAGA;IACE,kBAAA;IACA,gBAAA;EADF;AACF;AAIA;EACE;IACE,kBAAA;IACA,gBAAA;EAFF;EAIA;IACE,cAAA;IACA,gBAAA;EAFF;AACF;AAKA;EACE;IAAK,UAAA;EAFL;EAGA;IAAM,UAAA;EAAN;AACF;AAEA;EACE;IAAI,UAAA;EACJ;EAAA;IAAM,UAAA;EAGN;AACF;AAAA;EACE;IACE,wBAAA;IACA,gCAAA;EAEF;EAAA;IACE,2BAAA;IACA,mCAAA;EAEF;AACF","sourcesContent":["@keyframes fadeInOpacity4 {\n  0% {\n    opacity: 0.4;\n  }\n  5% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0.4;\n  }\n}\n\n@keyframes fadeInOpacity7 {\n  0% {\n    color: var(--color-elevated-success);\n    fill: var(--color-elevated-success);\n    stroke: var(--color-elevated-success);\n  }\n  50% {\n    color: var(--color-elevated-text);\n    fill: var(--color-elevated-draw);\n    stroke: var(--color-elevated-draw);\n  }\n}\n\n@keyframes slide-right {\n  from {\n    margin-left: 0;\n    margin-top: 3rem;\n  }\n  to {\n    margin-left: 13rem;\n    margin-top: 0rem;\n  }\n}\n\n@keyframes slide-left {\n  from {\n    margin-left: 13rem;\n    margin-top: 0rem;\n  }\n  to {\n    margin-left: 0;\n    margin-top: 3rem;\n  }\n}\n\n@keyframes product-fade-in {\n  0%  {opacity: 0}\n  100% {opacity: 1;}\n}\n\n@keyframes product-fade-out {\n  0% {opacity: 1;}\n  100% {opacity: 0;}\n}\n\n\n@keyframes blur-in {\n  from {\n    backdrop-filter: blur(0);\n    -webkit-backdrop-filter: blur(0);\n  }\n  to {\n    backdrop-filter: blur(10px);\n    -webkit-backdrop-filter: blur(10px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
