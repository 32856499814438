import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {selectDoubleFlat} from "@/data/select_double_flat.js";
import {SystemCommonDisplay} from "./system_common_display.jsx";
import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";
import IconDetailCollapse from "assets/icons/icon_detail_collapse.svg";
import IconDetailExpand from "assets/icons/icon_detail_expand.svg";

export function ControlServerCard(props) {
  const intl = useIntl();
  const controlServer = props.controlServer;

  const selectCalibrations = selectDoubleFlat("calibration");
  const filterCalibrations =  createSelector(selectCalibrations, (all, f) => {
    return all.filter(c => c.controlServer.id === controlServer.id);
  });
  const selectLatestCalibration = createSelector(filterCalibrations, (filtered) => {
    return filtered.reduce(
      (r, c) => (c.createdAt > r?.createdAt) ? c : r, filtered[0]
    );
  });
  const selectLatestCalibrationTimestamp = createSelector(selectLatestCalibration, (latest) => {
    return (!latest) ? null : (latest.couplerCalibrations || [])
        .concat(latest.qubitCalibrations)
        .map(d => d.calibratedAt)
        .reduce((r, d) => d > r ? d : r, null);
  });
  const selectReservations = selectDoubleFlat("reservation");
  const reservations = useSelector(
    selectReservations,
    rs => rs.find(
      r => !r.archivedAt && r.controlServer?.id === props.controlServer.id
    )
  );

  const calibratedAt = useSelector(selectLatestCalibrationTimestamp);

  const className = `system-card control-server ${props.className ?? ""}`
    + ` commands-${controlServer.commandStatus}`
    + ` ${(controlServer.availableAt) ? "" : "un"}available`
    + ` ${(controlServer.lockedAt) ? "" : "un"}locked`
    + ` ${(reservations?.some(r => r.active)) ? "" : "un"}reserved`
  ;
  return (
    <div className={className}>
      <SystemCommonDisplay system={props.controlServer}/>

      <div className="control-server-display">
        {Boolean(calibratedAt) && (
          <span>
            <span className="data-label">
              {intl.formatMessage({id: "systems.controlServer.calibratedAt.label"})}
            </span>
            <TimestampDisplay className="data-value" value={calibratedAt}/>
          </span>
        )}
        {Boolean(controlServer.lockedAt) && (
          <div className="control-server-locked-at">
            <span className="data-label">
              {intl.formatMessage({id: "systems.controlServer.lockedAt.label"})}
            </span>
            <TimestampDisplay className="data-value" value={controlServer.lockedAt}/>
          </div>
        )}

        {/* {Boolean(controlServer.reservations.length) && (
          <div className="control-server-reservations">
            <div className="control-server-reservations-title data-label">
              {intl.formatMessage({id: "systems.controlServer.reservations.title"})}
            </div>
            <ul className="control-server-reservations-list">
              {
                _.orderBy(controlServer.reservations, "activateAt", "desc").map((r) => {
                  const open = (r.activateAt > Date.now() && !r.rejectedAt);
                  return (
                    <li key={r.id} className={`reservation-line ${open ? "open" : ""} ${r.active ? "active" : ""}`}>
                      <div className="reservation-timestamp">
                        <TimestampDisplay className="reservation-activate-at" value={r.activateAt}/>
                      </div>
                      <div className="reservation-user">
                        - <UserDisplay className="reservation-recipient" value={r.recipient}/>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        )} */}

        {props.isActive && (
          <IconDetailCollapse/>
        )}
        {!props.isActive && (
          <IconDetailExpand/>
        )}
      </div>
    </div>
  );
}
