import React from "react";
import {useIntl} from "react-intl";

import {CalibrationIdentifierDisplay} from "./calibration_identifier_display.jsx";
import {PercentDisplay} from "@/library/display/percent_display.jsx";
import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";


export function CouplersDisplay(props) {
  const intl = useIntl();

  return (
    <table className={"calibration-table table couplers " + (props.className ?? "")}>
      <thead>
        <tr>
          <th className="component">
            {intl.formatMessage({id: "systems.calibration.couplers.column.identifier"})}
          </th>
          <th className="epg">
            {intl.formatMessage({id: "systems.calibration.couplers.column.swapEpg"})}
          </th>
          <th className="epg">
            {intl.formatMessage({id: "systems.calibration.couplers.column.eSwapEpg"})}
          </th>
          <th className="timestamp">
            {intl.formatMessage({id: "systems.calibration.couplers.column.calibratedAt"})}
          </th>
        </tr>
      </thead>
      <tbody>
        {props.couplers.map((coupler) => (
          <tr className="calibration-row row" key={props.calibration.id + coupler.identifier}>
            <td className="component"><CalibrationIdentifierDisplay value={coupler.identifier}/></td>
            <td className="epg"><PercentDisplay value={coupler.swapEpg} scale={1}/></td>
            <td className="epg"><PercentDisplay value={coupler.eSwapEpg} scale={1}/></td>
            <td className="timestamp"><TimestampDisplay value={coupler.calibratedAt}/></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
