import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {ServiceCallButton} from "@/library/controls/service_call_button.jsx";

function emptyCompiler() {
  return {identifier: ""};
}

const MIN_LENGTH = {
  identifier: 3
};

export function CompilerCardNew(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({identifier: true});
  const [invalids, setInvalids] = useState({identifier: false});
  const [compiler, setCompiler] = useState(emptyCompiler());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChange = (ev) => {
    setCompiler(Object.assign({}, compiler, {[ev.target.name]: ev.target.value}));
    if (ev.target.value.length > 0
        && ev.target.value.trim().length < MIN_LENGTH[ev.target.name]) {
      setInvalids({...invalids, [ev.target.name]: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, [ev.target.name]: true});
      setInvalids({...invalids, [ev.target.name]: false});
    } else {
      setBlanks({...blanks, [ev.target.name]: false});
      setInvalids({...invalids, [ev.target.name]: false});
    }
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      await Aqumen.Compiler.create(
        session, Aqumen.Utility.create("Compiler", session, compiler)
      );
      setCompiler(emptyCompiler());

    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "compiler-card new focusable-item");
  if (working) {
    className += " working";
  }
  return (
    <div className={className}>
      <h1 className="compiler-card-new-title">
        {intl.formatMessage({id: "operations.compilers.new.title"})}
      </h1>
      <div className="compiler-card-inputs">
        <label className="compiler-identifier">
          <span className="label-text">
            {intl.formatMessage({id: "operations.compilers.new.identifier"})}
          </span>
          <input className="compiler-identifier"
                 placeholder={intl.formatMessage({id: "operations.compilers.new.identifier.placeholder"})}
                 type="text"
                 disabled={working}
                 name="identifier"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={compiler.identifier}/>
        </label>
        <label className="compiler-group-identifier">
          <span className="label-text">
            {intl.formatMessage({id: "operations.compilers.new.groupIdentifier"})}
          </span>
          <input className="compiler-group-identifier"
                 placeholder={intl.formatMessage({id: "operations.compilers.new.groupIdentifier.placeholder"})}
                 type="text"
                 disabled={working}
                 name="groupIdentifier"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={compiler.groupIdentifier}/>
        </label>
        <label className="compiler-url">
          <span className="label-text">
            {intl.formatMessage({id: "operations.compilers.new.url"})}
          </span>
          <input className="compiler-url"
                 placeholder={intl.formatMessage({id: "operations.compilers.new.url.placeholder"})}
                 type="text"
                 disabled={working}
                 name="url"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={compiler.url}/>
        </label>
      </div>

      <div className="compiler-card-meta">
        <div className="compiler-card-actions">
          <div className="compiler-card-action">
            <ServiceCallButton
              className="compiler-create"
              disabled={disabled}
              messageId="operations.compilers.new.create.button"
              onClick={handleCreate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
