import Aqumen from "@aqumen/sdk";

import {setPreferences, APP_SETTINGS_PREFIX} from "./preferences_slice.js";

export const setPreference = (key, value) => (dispatch, getState) => {
  const state = getState();
  const session = state.accessControlSession;

  const previous = state.preference ?? {};
  const next = Object.assign({}, previous, {[key]: value});

  dispatch(setPreferences(next));
  return Aqumen.User.update(
    session, {id: session.user.id}, {preferences: JSON.stringify(next)}
  );
};
