export const userRequest = [{
  id: true,
  version: true,
  acceptedAgreements: {id: true, acceptedAt: true, agreement: {id: true}},
  archivedAt: true,
  azureCustomerSubscriptionId: true,
  createdAt: true,
  email: true,
  fullName: true,
  identifier: true,
  organization: {id: true, identifier: true},
  passkeys: {
    id: true,
    createdAt: true,
    lastIp: true,
    lastUsedAt: true,
    name: true
  },
  preferences: true,
  roles: {
    id: true,
    version: true,
    identifier: true,
    permissions: {
      id: true,
      version: true,
      action: true,
      level: true,
      target: true,
      targetCreatedBy: true,
      targetIdentifier: true,
      targetOrganization: true,
      targetType: true
    }
  },
  tokens: {
    id: true,
    version: true,
    createdAt: true,
    description: true,
    expiresAt: true,
    lastIp: true,
    lastUsedAt: true,
    receivedAt: true,
    type: true
  }
}];
