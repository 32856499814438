import _ from "lodash";
import {createSelector} from "@reduxjs/toolkit";

import {selectFlat} from "@/data/select_flat.js";
import {regexFromUser} from "@/library/utility/regex_from_user.js";
import {visibleRole} from "@/panes/roles/visible_role.js";

import {selectStoredFilters} from "./select_stored_filters.js";

const selectUnarchivedRoles = createSelector(
  selectFlat("role"), rs => rs.filter(r => !r.archivedAt)
);

const selectVisibleRoles = createSelector(
  selectUnarchivedRoles, ar => ar.filter(visibleRole)
);

const selectFilteredRoles = createSelector(
  selectVisibleRoles, selectStoredFilters, (ur, sf) => {
    const filterExp = regexFromUser(sf.roles);
    return ur.filter(r => {
      return filterExp.test(r.identifier)
        || filterExp.test(r.description)
        || filterExp.test(r.id);
    });
  }
);

export const selectRoles = createSelector(
  selectFilteredRoles, rs => _.orderBy(rs, "identifier", "asc")
);
