const MAX_SIGNED_INT64 = 9223372036854775807n;

export function fileObject({binary, birthtimeMs = Date.now(), mtimeMs, filePath, raw}) {
  const fileEncoding = (binary) ? "binary" : "utf8";
  const contentEncoding = (binary) ? "base64" : "utf8";
  const content = raw.toString(contentEncoding);
  return {
    content,
    encoding: contentEncoding,
    file: {
      createdAt: birthtimeMs.toString(10),
      encoding: fileEncoding,
      modifiedAt: mtimeMs ? mtimeMs.toString(10) : null,
      path: filePath,
      size: (binary) ? raw.byteLength : new Blob([raw]).size,
      xxhash: (globalThis.aqumenXXHash.h64(content) - MAX_SIGNED_INT64).toString(10)
    }
  };
}
