import Aqumen from "@aqumen/sdk";
import React, {useContext, useState} from "react";
import {get, parseRequestOptionsFromJSON} from "@github/webauthn-json/browser-ponyfill";
import {useIntl} from "react-intl"
import {useDispatch} from "react-redux";

import {accessControlRequest} from "./access_control_request.js";
import {ConnectionsContext} from "@/context/connections_context.js";
import LogoPasskey from "assets/icons/logo_passkey.svg";
import {processSession} from "./process_session.js";


export function SignInPasskeyTile(props) {
  console.group("SignInPasskeyTile");

  const dispatch = useDispatch();
  const intl = useIntl()

  const {connections, setConnections} = useContext(ConnectionsContext);

  const readyState = {
    classSuffix: "status-cta",
    message: intl.formatMessage({id: "signIn.cta"})
  }
  const [signInStatus, setSignInStatus] = useState(readyState);

  const signInAction = async () => {
    setSignInStatus({message: intl.formatMessage({id: "signIn.initializing"})});
    let sessionPayload;

    try {
      const initializeResponse = await accessControlRequest("/authentication/initialize");
      const initializeResponsePayload = await initializeResponse.json();
      if (initializeResponse.status >= 300) {
        const errorId = (new Aqumen.Error.AqumenError(initializeResponsePayload?.errors?.[0] || {})).id;
        setSignInStatus({
          classSuffix: "error", message: intl.formatMessage(
            {id: "signIn.error.unknown.initializing"}, {errorId}
          )
        });
        return;
      }

      setSignInStatus({
        classSuffix: "normal", message: intl.formatMessage({id: "signIn.finalizing"})
      });

      const getOptions = parseRequestOptionsFromJSON(initializeResponsePayload.data);
      const getResult = await get(getOptions);
      const finalizeResponse = await accessControlRequest("/authentication/finalize", getResult);
      sessionPayload = await finalizeResponse.json();

      if (finalizeResponse.status >= 300) {
        if (sessionPayload?.errors?.[0]?.message === "unauthorized") {
            setSignInStatus({
              classSuffix: "error", message: intl.formatMessage({id: "signIn.error.passkey.unrecognized"})
            });
            setTimeout(() => setSignInStatus(readyState), 3000);
        } else {
          const errorId = (new Aqumen.Error.AqumenError(initializeResponsePayload?.errors?.[0] || {})).id;
          setSignInStatus({
            classSuffix: "error", message: intl.formatMessage(
              {id: "signIn.error.unknown.finalizing"}, {errorId}
            )
          });
        }

        return;
      }
    } catch (e) {
      const errorId = (new Aqumen.Error.AqumenError(e)).id;
      switch (e.name) {
        case "AbortError":
          setSignInStatus({classSuffix: "error", message: intl.formatMessage(
            {id: "signIn.exception.aborted"}, {errorId}
          )});
          break;
        case "NotAllowedError":
          setSignInStatus(readyState);
          break;
        case "TypeError":
          setSignInStatus({classSuffix: "error", message: intl.formatMessage(
            {id: "signIn.exception.network"}, {errorId}
          )});
          break;
        default:
          setSignInStatus({classSuffix: "error", message: intl.formatMessage(
            {id: "signIn.exception.unknown"}, {errorId}
          )});
      }
      return;
    }

    try {
      setSignInStatus({
        classSuffix: "success", message: intl.formatMessage({id: "signIn.passkeyAccepted"})
      });

      const newConnections = await processSession(dispatch, sessionPayload);
      setConnections(newConnections);
      if (newConnections.length > 1) {
        setSignInStatus({
          classSuffix: "success",
          message: intl.formatMessage(
            {id: "signIn.multipleEnvironments"}, {count: connections.length}
          )
        });
        await new Promise(r => setTimeout(r, 750));
      }

      setSignInStatus(readyState);
      dispatch({type: "@@minimum-router/navigate", path: "/dashboard"});

    } catch (e) {
      console.error(e);
      setSignInStatus({
        classSuffix: "error", message: intl.formatMessage({id: "signIn.error"})
      });
    }
  }

  try {
    return (
      <div className={"tile sign-in" + (props.className ?? "")}>
        <button onClick={signInAction} className="button sign-in">
          {intl.formatMessage({id: "signIn.button"})}
        </button>
        <LogoPasskey aria-label={intl.formatMessage({id: "aria.label.logo.passkey"})}/>
        <p className={"help sign-in cta status-area status-" + signInStatus.classSuffix}>
          {signInStatus.message}
        </p>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
