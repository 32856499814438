import {agreementRequest} from "./agreement/agreement_request.js";
import {compilerRequest} from "./compiler/compiler_request.js";
import {configurationRequest} from "./configuration/configuration_request.js";
import {controlServerRequest} from "./control_server/control_server_request.js";
import {jobQueueRequest} from "./job_queue/job_queue_request.js";
import {jobRequest} from "./job/job_request.js";
import {metricRequest} from "./metric/metric_request.js";
import {organizationRequest} from "./organization/organization_request.js";
import {permissionRequest} from "./permission/permission_request.js";
import {reservationRequest} from "./reservation/reservation_request.js";
import {roleRequest} from "./role/role_request.js";
import {simulatorRequest} from "./simulator/simulator_request.js";
import {userRequest} from "./user/user_request.js";

export const Query = {
  Agreement: {
    type: "agreements",
    filter: agreementRequest[1],
    selection: agreementRequest[0]
  },
  Compiler: {
    type: "compilers",
    filter: compilerRequest[1],
    selection: compilerRequest[0]
  },
  Configuration: {
    type: "configurations",
    filter: configurationRequest[1],
    selection: configurationRequest[0]
  },
  ControlServer: {
    type: "controlServers",
    filter: controlServerRequest[1],
    selection: controlServerRequest[0]
  },
  JobQueue: {
    type: "jobQueues",
    filter: jobQueueRequest[1],
    selection: jobQueueRequest[0]
  },
  Job: {
    type: "jobs",
    filter: jobRequest[1],
    selection: jobRequest[0]
  },
  Metric: {
    type: "metrics",
    filter: metricRequest[1],
    selection: metricRequest[0]
  },
  Organization: {
    type: "organizations",
    filter: organizationRequest[1],
    selection: organizationRequest[0]
  },
  Permission: {
    type: "permissions",
    filter: permissionRequest[1],
    selection: permissionRequest[0]
  },
  Reservation: {
    type: "reservations",
    filter: reservationRequest[1],
    selection: reservationRequest[0]
  },
  Role: {
    type: "roles",
    filter: roleRequest[1],
    selection: roleRequest[0]
  },
  Simulator: {
    type: "simulator",
    filter: simulatorRequest[1],
    selection: simulatorRequest[0]
  },
  User: {
    type: "users",
    filter: userRequest[1],
    selection: userRequest[0]
  }
};
