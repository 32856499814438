import _ from "lodash";
import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "AccessControlSession",
  initialState: {},
  reducers: {
    "setAccessControlSession": (_state, action) => {
      return _.pick(action.payload, ["url", "key", "user"]);
    },
    "setCurrentUser": (state, action) => {
      state.user = action.payload.find(u => u.id === state.user.id);
    }
  }
});

export const {setAccessControlSession, setCurrentUser} = slice.actions;
export const {reducer} = slice;
