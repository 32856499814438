const SIX_MONTHS = 15_778_800_000;

export function formatTimestamp(timestamp, options = {}) {
  if (!timestamp) {
    return null;
  }
  const dt = new Date(timestamp);
  const now = new Date();

  let format = null;
  if (!options.omitDate && (
      options.forceDate || (!options.forceTime && Math.abs(now.valueOf() - timestamp) > SIX_MONTHS))) {

    format = { // "ll" Jan-Dec 1-31, 2024
      month: "short",
      day: "numeric",
      year: "numeric"
    };
  } else if (!options.omitDate && dt?.getYear() !== now.getYear()) {
    format = { // "LT ddd MMM D" h:mm A Sun-Sat Jan-Dec 1-31
      hour: "numeric",
      minute: "2-digit",
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric"
    };
  } else if (!options.omitDate && (dt?.getMonth() !== now.getMonth() || dt?.getDate() !== now.getDate())) {
    format = { // "LT ddd MMM D" h:mm A Sun-Sat Jan-Dec 1-31
      hour: "numeric",
      minute: "2-digit",
      weekday: "short",
      month: "short",
      day: "numeric"
    };
  } else {
    format = { // "LT" h:mm A
      hour: "numeric",
      minute: "2-digit"
    };
  }
  if (format.minute && options.forceSeconds) {
    format.second = "numeric";
  }
  return new Intl.DateTimeFormat(undefined, format).format(timestamp);
}
