import React from "react";

import {JobTimestampDisplay} from "./job_timestamp_display.jsx";

export function JobTimingDetailedTile(props) {
  const job = props.job;
  if (_.isEmpty(job.history)) {
    return null;
  }

  return (
    <div className="job-timing-detailed-tile tile">
      <JobTimestampDisplay
        forceSeconds={true}
        messageEmptyId="jobs.job.detail.createdAt.empty"
        messageId="jobs.job.detail.createdAt.label"
        omitDate={true}
        value={job.createdAt}
      />
      <JobTimestampDisplay
        forceSeconds={true}
        messageEmptyId="jobs.job.detail.receivedAt.empty"
        messageId="jobs.job.detail.receivedAt.label"
        omitDate={true}
        value={job.receivedAt}
      />
      <JobTimestampDisplay
        forceSeconds={true}
        messageEmptyId="jobs.job.detail.startedAt.empty"
        messageId="jobs.job.detail.startedAt.label"
        omitDate={true}
        value={job.startedAt}
      />
      <JobTimestampDisplay
        forceSeconds={true}
        messageEmptyId="jobs.job.detail.signaledAt.empty"
        messageId="jobs.job.detail.signaledAt.label"
        omitDate={true}
        value={job.signaledAt}
      />
      <JobTimestampDisplay
        forceSeconds={true}
        messageEmptyId="jobs.job.detail.endedAt.empty"
        messageId="jobs.job.detail.endedAt.label"
        omitDate={true}
        value={job.endedAt}
      />
      <JobTimestampDisplay
        forceSeconds={true}
        messageEmptyId="jobs.job.detail.exitedAt.empty"
        messageId="jobs.job.detail.exitedAt.label"
        omitDate={true}
        value={job.exitedAt}
      />
    </div>
  );
}
