import React from "react";
import {useIntl} from "react-intl";

import {formatTimestamp} from "@/library/format/format_timestamp.js";

export function UserTimestampsTile(props) {
  const intl = useIntl();

  const lastAgreedAt = Math.max(0, ...(props.user.acceptedAgreements || []).map(u => u.acceptedAt));
  return (
    <div className="user-timestamps-tile">
      {Boolean(props.user.createdAt) && (
      <div className="user-timestamps-created">
        {intl.formatMessage(
          {id: "users.user.timestamps.created"},
          {createdAt: formatTimestamp(props.user.createdAt, {forceDate: true})}
        )}
      </div>
      )}
      <div className="user-timestamps-created">
        {Boolean(lastAgreedAt) && intl.formatMessage(
          {id: "users.user.timestamps.agreed"},
          {agreedAt: formatTimestamp(lastAgreedAt, {forceDate: true})}
        )}
        {!lastAgreedAt && intl.formatMessage({id: "users.user.timestamps.noAgreement"})}
      </div>
    </div>
  );
}
