import React, {useState} from "react";
import {useIntl} from "react-intl";

export function UserCard(props) {
  const intl = useIntl();

  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    setFocused(!focused);
    props.onClick();
  };

  let className = "user-card card";
  if (focused) {
    className += " focused";
  }
  if (props.selected) {
    className += " selected";
  }
  if (props.inAnySelected) {
    className += " in-any-selected";
  }
  if (props.inAllSelected) {
    className += " in-all-selected";
  }
  if (props.adding) {
    className += " adding";
  }
  if (props.removing) {
    className += " removing";
  }
  if (props.editing) {
    className += " editing";
  }
  return (
    <div className={className} onClick={handleClick}>
      <div className="editing-decoration">! CHANGING</div>
      <div className="adding-decoration">+ ADDING</div>
      <div className="removing-decoration">- REMOVING</div>
      <div className="user-full-name">{props.user.fullName}</div>
      {props.user.organization?.identifier && (
        <div className="user-organization present">
          {props.user.organization.identifier}
        </div>
      )}
      {!props.user.organization?.identifier && (
        <div className="user-organization none">
          {intl.formatMessage({id: "accessControl.user.organization.none"})}
        </div>
      )}
      <div className="user-email">{props.user.email}</div>
    </div>
  );
}
