import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {ServiceCallButton} from "@/library/controls/service_call_button.jsx";

function emptyControlServer() {
  return {identifier: "", publicKey: "", jobTermination: []};
}

const MIN_LENGTH = {
  identifier: 3,
  publicKey: 10
};

export function ControlServerCardNew(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({identifier: true, publicKey: true});
  const [invalids, setInvalids] = useState({identifier: false, publicKey: false});
  const [controlServer, setControlServer] = useState(emptyControlServer());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChange = (ev) => {
    setControlServer(Object.assign({}, controlServer, {[ev.target.name]: ev.target.value}));
    if (ev.target.value.length > 0
        && ev.target.value.trim().length < MIN_LENGTH[ev.target.name]) {
      setInvalids({...invalids, [ev.target.name]: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, [ev.target.name]: true});
      setInvalids({...invalids, [ev.target.name]: false});
    } else {
      setBlanks({...blanks, [ev.target.name]: false});
      setInvalids({...invalids, [ev.target.name]: false});
    }
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      await Aqumen.ControlServer.create(
        session, Aqumen.Utility.create("ControlServer", session, controlServer)
      );
      setControlServer(emptyControlServer());

    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "control-server-card new focusable-item");
  if (working) {
    className += " working";
  }
  return (
    <div className={className}>
      <h1 className="control-server-card-new-title">
        {intl.formatMessage({id: "operations.controlServers.new.title"})}
      </h1>
      <div className="control-server-card-inputs">
        <label className="control-server-identifier">
          <span className="label-text">
            {intl.formatMessage({id: "operations.controlServers.new.identifier"})}
          </span>
          <input className="control-server-identifier"
                 placeholder={intl.formatMessage({id: "operations.controlServers.new.identifier.placeholder"})}
                 type="text"
                 disabled={working}
                 name="identifier"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={controlServer.identifier}/>
        </label>
        <label className="control-server-public-key">
          <span className="label-text">
            {intl.formatMessage({id: "operations.controlServers.new.publicKey"})}
          </span>
          <input className="control-server-public-key"
                 placeholder={intl.formatMessage({id: "operations.controlServers.new.publicKey.placeholder"})}
                 type="text"
                 disabled={working}
                 name="publicKey"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={controlServer.publicKey}/>
        </label>
      </div>

      <div className="control-server-card-meta">
        <div className="control-server-card-actions">
          <div className="control-server-card-action">
            <ServiceCallButton
              className="control-server-create"
              disabled={disabled}
              messageId="operations.controlServers.new.create.button"
              onClick={handleCreate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
