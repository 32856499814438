import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";

export function OrganizationIdentifierInput(props) {
  const intl = useIntl();

  return (
    <label className="organization-identifier">
      <span className="label-text">
        {intl.formatMessage({id: `organizations.${props.messageKey}.identifier.label`})}
      </span>
      <input className="organization-identifier"
        placeholder={intl.formatMessage({id: `organizations.${props.messageKey}.identifier.placeholder`})}
        type="text"
        disabled={props.disabled}
        onChange={props.onChange}
        onKeyUp={blurOnEnter}
        value={props.organization.identifier}
      />
    </label>
  );
}
