// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --border-elevated: var(--border-width) solid var(--color-elevated-border);
  --border-selected: var(--border-width) solid var(--color-selected-border);
  --opacity-disabled: 0.5;
  --opacity-inactive: 0.4;
}

body {
  background-color: var(--color-elevated-canvas);
}

a {
  color: var(--color-emphasis-text);
}

h1, h2 {
  color: var(--color-elevated-text);
}

.data-label {
  color: var(--color-base-text);
}

.data-value {
  color: var(--color-elevated-text);
}

.focusable-collection:has(.focusable-item:focus-within) .focusable-item:not(:has(:focus-within)):not(.confirming),
.focusable-collection:has(.focusable-item.confirming) .focusable-item:not(:has(:focus-within)):not(.confirming) {
  opacity: var(--opacity-inactive);
  border-bottom-color: transparent;
}
.focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within), .focusable-collection:has(.focusable-item:focus-within) .focusable-item.confirming,
.focusable-collection:has(.focusable-item.confirming) .focusable-item:has(:focus-within),
.focusable-collection:has(.focusable-item.confirming) .focusable-item.confirming {
  border-color: var(--color-selected-border);
}
.focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) input, .focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) select, .focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) textarea, .focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) .label-text, .focusable-collection:has(.focusable-item:focus-within) .focusable-item.confirming input, .focusable-collection:has(.focusable-item:focus-within) .focusable-item.confirming select, .focusable-collection:has(.focusable-item:focus-within) .focusable-item.confirming textarea, .focusable-collection:has(.focusable-item:focus-within) .focusable-item.confirming .label-text,
.focusable-collection:has(.focusable-item.confirming) .focusable-item:has(:focus-within) input,
.focusable-collection:has(.focusable-item.confirming) .focusable-item:has(:focus-within) select,
.focusable-collection:has(.focusable-item.confirming) .focusable-item:has(:focus-within) textarea,
.focusable-collection:has(.focusable-item.confirming) .focusable-item:has(:focus-within) .label-text,
.focusable-collection:has(.focusable-item.confirming) .focusable-item.confirming input,
.focusable-collection:has(.focusable-item.confirming) .focusable-item.confirming select,
.focusable-collection:has(.focusable-item.confirming) .focusable-item.confirming textarea,
.focusable-collection:has(.focusable-item.confirming) .focusable-item.confirming .label-text {
  color: var(--color-elevated-text);
  opacity: 1;
}

.focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) input, .focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) select, .focusable-collection:has(.focusable-item:focus-within) .focusable-item:has(:focus-within) textarea {
  border-color: var(--color-selected-border);
}

.focusable-item {
  border-top-color: transparent;
  border-bottom-color: var(--color-elevated-border);
}

.logo.qci {
  fill: var(--color-elevated-draw);
  opacity: var(--opacity-logo-qci);
}

.pane {
  color: var(--color-elevated-text);
}`, "",{"version":3,"sources":["webpack://./src/style/color_foundation.scss"],"names":[],"mappings":"AAAA;EACE,yEAAA;EACA,yEAAA;EAEA,uBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,8CAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAGA;EACE,6BAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAKE;;EACE,gCAAA;EACA,gCAAA;AADJ;AAGE;;;EACE,0CAAA;AACJ;AAAI;;;;;;;;;EACE,iCAAA;EACA,UAAA;AAUN;;AAHI;EACE,0CAAA;AAMN;;AADA;EACE,6BAAA;EACA,iDAAA;AAIF;;AADA;EACE,gCAAA;EACA,gCAAA;AAIF;;AADA;EACE,iCAAA;AAIF","sourcesContent":[":root {\n  --border-elevated: var(--border-width) solid var(--color-elevated-border);\n  --border-selected: var(--border-width) solid var(--color-selected-border);\n\n  --opacity-disabled: 0.5;\n  --opacity-inactive: 0.4;\n}\n\nbody {\n  background-color: var(--color-elevated-canvas);\n}\n\na {\n  color: var(--color-emphasis-text);\n}\n\nh1, h2 {\n  color: var(--color-elevated-text);\n}\n\n.data-label {\n  color: var(--color-base-text);\n}\n\n.data-value {\n  color: var(--color-elevated-text);\n}\n\n.focusable-collection:has(.focusable-item:focus-within),\n.focusable-collection:has(.focusable-item.confirming) {\n  .focusable-item:not(:has(:focus-within)):not(.confirming) {\n    opacity: var(--opacity-inactive);\n    border-bottom-color: transparent;\n  }\n  .focusable-item:has(:focus-within), .focusable-item.confirming {\n    border-color: var(--color-selected-border);\n    input, select, textarea, .label-text {\n      color: var(--color-elevated-text);\n      opacity: 1;\n    }\n  }\n}\n\n.focusable-collection:has(.focusable-item:focus-within) {\n  .focusable-item:has(:focus-within) {\n    input, select, textarea {\n      border-color: var(--color-selected-border);\n    }\n  }\n}\n\n.focusable-item {\n  border-top-color: transparent;\n  border-bottom-color: var(--color-elevated-border);\n}\n\n.logo.qci {\n  fill: var(--color-elevated-draw);\n  opacity: var(--opacity-logo-qci);\n}\n\n.pane {\n  color: var(--color-elevated-text);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
