export const simulatorRequest = [{
  id: true,
  archivedAt: true,
  availableAt: true,
  identifier: true,
  groupIdentifier: true,
  jobQueue: {id: true},
  type: true,
  url: true
}];
