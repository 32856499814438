import _ from "lodash";
import React from "react";

// import IconQubits from "assets/icons/icon_qubits.svg";
import {SystemCommonDisplay} from "./system_common_display.jsx";

export function SimulatorCard(props) {
  const className = `system-card simulator ${props.className ?? ""}`
    + ` ${(props.simulator.available) ? "" : "un"}available`
  ;

  return (
    <div className={className}>
      {/* <IconQubits aria-label={intl.formatMessage({id: "aria.label.icon.qubits"})}/> */}

      <SystemCommonDisplay system={props.simulator}/>
    </div>
  );
}
