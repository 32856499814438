import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {toggleAppSetting} from "@/data/preferences/toggle_app_setting.js";

export function SettingToggleButton({className = "", pane, setting}) {
  const dispatch = useDispatch();
  const intl = useIntl();

console.debug("SettingToggleButton", {className, pane, setting})
  const settingKey = `${pane}.${setting}`;
  const messageId = `${pane}.settings.${setting}.button`;

  const currentSetting = useSelector(selectAppSetting(settingKey));
  const handleClick = () => dispatch(toggleAppSetting(settingKey));

  let currentClassName = `setting-toggle-button ${className}`;
  if (currentSetting) {
    currentClassName += " current";
  }

  return (
    <button className={currentClassName} onClick={handleClick}>
      {intl.formatMessage({id: messageId})}
    </button>
  );
}
