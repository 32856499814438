import React from "react";

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "./constants.js";


export class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirming: false,
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    const handleReset = () => {
      if (this.state.confirmingReset) {
        this.props.dispatch({type: "CLEAR"});
        return;
      }
      this.setState({confirmingReset: true});
      setTimeout(
        () => this.setState({confirmingReset: false}), CONFIRM_DESTRUCTIVE_TIMEOUT_MS
      );
    };

    const buttonMessage = (this.state.confirming) ? "Please Confirm" : "Sign Out and Reset";

    return (
      <div className="error-boundary app">
        <div className="error-label">
          Aqumen Portal encountered an unrecoverable error:
        </div>
        <div className="error-message">
          {this.state.error.message}
        </div>
        <div className="error-id">
          {this.state.error.id}
        </div>
        <button className="error-reset-button" onClick={handleReset}>
          {buttonMessage}
        </button>
      </div>
    );
  }
}
