import Aqumen from "@aqumen/sdk";
import React from "react";

import {ServiceCallButton} from "@/library/controls/service_call_button.jsx";

const ACTION_NAME = "toggleLock";

const action = (controlServer) => (controlServer.lockedAt) ? "unlock" : "lock";

export function ControlServerToggleLockButton({className = "", confirming, controlServer}) {
  const disabled = controlServer.archivedAt || (confirming && confirming !== ACTION_NAME);
  const messageId = `operations.controlServers.controlServer.actions.${action(controlServer)}`;

  const handleClick = async () => {
    return Aqumen.ControlServer[action(controlServer)](controlServer.session, {id: controlServer.id});
  };

  return (
    <ServiceCallButton
      className={`action-button control-server-action-button toggle-lock-button ${className}`}
      disabled={disabled}
      messageId={messageId}
      onClick={handleClick}
    />
  );
}
