import React from "react";
import {injectIntl} from "react-intl";


class Boundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return (
      <div className="error-boundary control-server">
        <div className="error-label">
          {this.props.intl.formatMessage({id: "error.controlServer.label"}, {...this.props.controlServer})}
        </div>
        <div className="error-message">
          {this.state.error.message}
        </div>
        {this.state.error.id && (
          <div className="error-id">
            {this.props.intl.formatMessage({id: "error.controlServer.id"}, {id: this.state.error.id})}
          </div>
        )}
      </div>
    );
  }
}

export const ControlServerErrorBoundary = injectIntl(Boundary);
