import _ from "lodash";
import {createEnvironmentSlice} from "@/data/create_environment_slice.js";

export const {reducer, replace, update} = createEnvironmentSlice("Calibration", {
  extraReducers: (builder) => builder
    .addCase("sessions/deauthenticate", (state, action) => _.omit(state, action.payload.url))
    .addCase("ControlServer/replace", (state, action) => {
      action.payload.forEach(controlServer => controlServer.calibrations.forEach(a => {
        state[action.session.url] ||= {};
        state[action.session.url][a.id] = Object.assign(
          {}, a, {controlServer: _.pick(controlServer, ["id", "session.url"])}
        );
      }));
    })
});
