import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";
import {selectApplyCommon} from "@/data/select_apply_common.js";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";

import {CompilerCard} from "./compiler_card.jsx";
import {CompilerCardNew} from "./compiler_card_new.jsx";
import {CompilerErrorBoundary} from "./compiler_error_boundary.jsx";
import {SettingToggleButton} from "@/library/controls/setting_toggle_button.jsx";

export function CompilersPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectIncludeArchived = selectAppSetting("operations.compilers.includeArchived");
  const selectFilterArchived = createSelector(selectIncludeArchived, ia => !ia);
  const selectCompilers = selectApplyCommon("compiler", selectFilterArchived);

  const compilers = useSelector(selectCompilers);
  const includeArchived = useSelector(selectIncludeArchived);
  const sessions = useSelector(s => s.environmentSession);
  const showNewCardAlways = useSelector(selectAppSetting("operations.compilers.showNewCardAlways"));
  const showNewCardOnce = useSelector(selectAppSetting("operations.compilers.showNewCardOnce"));

  const showNewCard = showNewCardAlways || showNewCardOnce;

  useEffect(
    loadAndUnloadArchivable("Compiler", dispatch, sessions, includeArchived),
    [includeArchived]
  );

  return (
    <div className="compilers-pane pane focusable-collection">
      <div className="pane-top">
        <h1 className="compilers-title pane-title">
          {intl.formatMessage({id: "operations.compilers.title"})}
        </h1>
        <ActionsTile pane="operations.compilers" className="pane-actions">
          {!showNewCardAlways && (
            <SettingToggleButton className="show-new-card-once" setting="showNewCardOnce"/>
          )}
        </ActionsTile>
        <SettingsTile pane="operations.compilers">
          <SettingToggleCheckbox setting="includeArchived"/>
          <SettingToggleCheckbox setting="showNewCardAlways"/>
        </SettingsTile>
      </div>
      <div className="compilers-list">
        {showNewCard && (
          <CompilerCardNew/>
        )}
        {compilers.map(compiler => (
          <CompilerErrorBoundary compiler={compiler} key={compiler.id}>
            <CompilerCard
              compiler={compiler}
              session={compiler.session}
            />
          </CompilerErrorBoundary>
        ))}
      </div>
    </div>
  );
}
