import _ from "lodash";
import {createSlice} from "@reduxjs/toolkit";

export function createEnvironmentSlice(name, override = {}) {
  const slice = createSlice({
    name,
    initialState: {},
    reducers: {
      "merge": (state, action) => {
        state[action.session.url] = {
          ...state[action.session.url],
          ..._.keyBy(action.payload, "id")
        };
      },
      "remove": (state, action) => {
        Object.keys(state).forEach((sessionUrl) => {
          state[sessionUrl] = Object.keys(state[sessionUrl]).reduce((r, id) => {
            if (!action.payload[sessionUrl][id]) {
              r[id] = state[sessionUrl][id];
            }
            return r;
          }, {});
        });
      },
      "replace": (state, action) => {
        state[action.session.url] = _.keyBy(action.payload, "id");
      },
      "update": (state, action) => {
        state[action.session.url] ||= {};
        state[action.session.url][action.payload.id] = action.payload;
      }
    },
    extraReducers: (builder) => builder
      .addCase("sessions/deauthenticate", (state, action) => _.omit(state, action.payload.url)),

    ...override
  });

  return {
    slice,
    merge: slice.actions.merge,
    reducer: slice.reducer,
    remove: slice.actions.remove,
    replace: slice.actions.replace,
    update: slice.actions.update
  };
}
