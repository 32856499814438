import {ONE_DAY_MS} from "@/constants";

export function calculateRelativeActivation(start, end) {
  const now = Date.now();
  if (now >= start && now <= end) {
    return "active";
  }
  if (now > end) {
    return "past";
  }
  if (now + ONE_DAY_MS > start) {
    return "near";
  }

  return "far";
}
