import {ONE_DAY_MS} from "@/constants.js";

export function dashboardPrunePeriodDays(environmentConfigurations, organization) {
  let displayPeriod = organization?.prunePeriod;
  if (!displayPeriod) {
    const environmentPeriods = Object.values(environmentConfigurations).map(
      ec => Object.values(ec).find(c => c.identifier === "default_prune_jobs_interval")?.value
    );
    displayPeriod = Math.min(...environmentPeriods);
  }
  return Math.round(displayPeriod / ONE_DAY_MS);
}
