import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {editableRole} from "./editable_role.js";

export function RoleIdentifierTile(props) {
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const [identifier, setIdentifier] = useState(props.role.identifier ?? "");

  const submit = (identifier) => Aqumen.Role.update(
    session, {id: props.role.id}, {identifier}
  );
  const debouncedSubmit = useDebouncedCallback(submit, 1000);

  const handleChange = async (event) => {
    setIdentifier(event.target.value);
    return;
    // TODO: identifiers have server-enforced uniqueness, so this should have
    //       a non-standard GUI, e.g., a submit button or something.
    // await debouncedSubmit(event.target.value);
  };

  const handleBlur = (event) => {
    debouncedSubmit.cancel();
    if (event.target.value !== props.role.identifier) {
      return submit(event.target.value);
    }
  };

  return (
    <div className="role-identifier-tile">
      <label className="role-identifier label">
        <span className="role-identifier label-text">
          {intl.formatMessage({id: "roles.role.identifier.label"})}
        </span>
        <input className="role-identifier"
               type="text"
               disabled={!editableRole(props.role)}
               value={identifier}
               onBlur={handleBlur}
               onChange={handleChange}
               onKeyUp={blurOnEnter}/>
      </label>
    </div>
  );
}
