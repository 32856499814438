import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "./library/utility/blur_on_enter.js";
import IconClear from "assets/icons/icon_clear.svg";
import IconInfo from "assets/icons/icon_info.svg";
import {setUserInterface} from "./data/user_interface/user_interface_slice.js";


export function SearchTile() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const storedSearch = useSelector(s => s.userInterface?.search || "");
  const [focus, setFocus] = useState(false);
  const [help, setHelp] = useState(false);
  const [search, setSearch] = useState(storedSearch);
  const nav = useSelector(s => s.router?.path);

  const submit = () => {
    dispatch(setUserInterface({"search": search}));
  };
  const debouncedSubmit = useDebouncedCallback(submit, 1000);
  useEffect(() => {
    (search && focus) ? debouncedSubmit() : submit();
  }, [search, focus]);
  useEffect(() => {
    if (storedSearch !== search) {
      setSearch(storedSearch);
    }
  }, [storedSearch]);
  useEffect(() => {
    if (nav === "/jobs" && storedSearch) {
      setHelp(false);
    }
  }, [nav, storedSearch]);

  const handleBlur = () => setFocus(false);
  const handleChange = ev => setSearch(ev.target.value);
  const handleClear = () => setSearch("");
  const handleFocus = ev => {
    ev.target.select();
    setFocus(true);
  };
  const handleHelp = () => setHelp(!help);
  const handleKeyUp = (ev) => {
    if (ev.key === "Enter") {
      dispatch({type: "@@minimum-router/navigate", path: "/jobs"});
    }
    blurOnEnter(ev);
  };

  let className = "search-tile";
  if (help) {
    className += " help";
  }

  return (
    <div className={className}>
      <div className="search-help-backdrop" onClick={handleHelp}></div>
        <label className="search-label">
          <span className="search-label-text">
            {intl.formatMessage({id: "search.label"})}
          </span>
          <input className="search-input"
              placeholder={intl.formatMessage({id: "search.placeholder"})}
              value={search}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyUp={handleKeyUp}/>
          <button className="search-clear-button"
                  aria-label={intl.formatMessage({id: "search.clear.aria.label"})}
                  disabled={!search}
                  onClick={handleClear}>
            <IconClear/>
          </button>
          <button className="search-help-button"
                  aria-label={intl.formatMessage({id: "search.help.aria.label"})}
                  disabled={search}
                  onClick={handleHelp}>
            <IconInfo/>
          </button>
          <div className="search-help-copy">
            <p>{intl.formatMessage({id: "search.help.copy"})}</p>
            <ul className="search-examples">
              <li>
                <span className="search-example-label">created by alice or bob: </span>
                <span className="search-example-example">(alice|bob)</span>
              </li>
              <li>
                <span className="search-example-label">executing a file with super and position in its name: </span>
                <span className="search-example-example">super.*position</span>
              </li>
              <li>
                <span className="search-example-label">having a status of completed</span>
                <span className="search-example-example">completed</span>
              </li>
              <li>
                <span className="search-example-label">started or completed between 2022-01-01 and 2023-12-31</span>
                <span className="search-example-example">2022-2023</span>
              </li>
              <li>
                <span className="search-example-label">started or completed after December 13th, 2023</span>
                <span className="search-example-example">from dec 13, 2023</span>
              </li>
            </ul>
          </div>
        </label>
    </div>
  );
}
