import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {SendButton} from "./send_button.jsx";

export function UserEmailTile(props) {
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const [email, setEmail] = useState(props.user.email ?? "");

  const submit = (value) => {
    Aqumen.User.update(session, {id: props.user.id}, {email: value});
  };
  const debouncedSubmit = useDebouncedCallback(submit, 1000);

  const handleChange = (event) => {
    setEmail(event.target.value);
    debouncedSubmit(event.target.value);
  };

  const handleBlur = (event) => {
    debouncedSubmit.cancel();
    if (event.target.value !== props.user.email) {
      return submit(event.target.value);
    }
  };

  const disabled = Boolean(props.user.archivedAt);
  return (
    <div className="user-email-tile">
            <SendButton user={props.user}
                      confirm={true}
                      disabled={props.confirmingArchive}
                      messagePrefix="users.user"
                      setConfirming={props.setConfirmingRegistration}/>
      <label className="user-email label">
        <span className="user-email label-text">
          {intl.formatMessage({id: props.messagePrefix + ".email.label"})}
        </span>
        <input className="user-email"
               type="email"
               value={email}
               disabled={disabled}
               onBlur={handleBlur}
               onChange={handleChange}
               onKeyUp={blurOnEnter}/>
      </label>
    </div>
  );
}
