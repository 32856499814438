import Aqumen from "@aqumen/sdk";

import {setCurrentUser} from "@/data/access_control_session/access_control_session_slice.js";
import {Query} from "@/data/query.js";

import {update as updateUser} from "./user_slice.js";

export async function reloadCurrentUser(dispatch, session) {
  const latest = await Aqumen.User.me(session, Query.User.selection);
  dispatch(updateUser(latest));
  dispatch(setCurrentUser([latest]));
};
