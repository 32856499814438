export function groupSimulators(hydratedSimulators) {
  return Object.values(hydratedSimulators.reduce((r, s) => {
    r[s.groupIdentifier] ||= {};
    r[s.groupIdentifier].identifier ||= s.groupIdentifier;

    r[s.groupIdentifier].__typename = s.__typename;
    r[s.groupIdentifier].available ||= s.available;
    r[s.groupIdentifier].availableAt ||= s.availableAt;
    r[s.groupIdentifier].jobQueue ||= {queued: []};
    r[s.groupIdentifier].jobQueue.queued = Array.from(
      (new Set(r[s.groupIdentifier].jobQueue?.queued || [])).add(s.jobQueue?.queued || [])
    );
    return r;
  }, {}));
}
