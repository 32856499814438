// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon {
  color: var(--color-elevated-text);
}
.coming-soon.pane {
  padding: 1rem 2rem;
}
.coming-soon .title {
  font-weight: lighter;
  font-size: 1.6rem;
  text-align: left;
}
.coming-soon .title em {
  font-style: normal;
  font-weight: normal;
}
.coming-soon .description {
  color: var(--color-elevated-text);
  font-size: 1rem;
  text-align: left;
}
.coming-soon .today {
  color: var(--color-base-text);
  font-size: 0.9rem;
  border: 1px solid var(--color-elevated-hint);
  padding: 1rem;
  text-align: left;
}
.coming-soon .today code {
  color: var(--color-elevated-text);
}`, "",{"version":3,"sources":["webpack://./src/style/panes/coming_soon.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,oBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,mBAAA;AAAN;AAGE;EACE,iCAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAGE;EACE,6BAAA;EACA,iBAAA;EACA,4CAAA;EACA,aAAA;EACA,gBAAA;AADJ;AAGI;EACE,iCAAA;AADN","sourcesContent":[".coming-soon {\n  color: var(--color-elevated-text);\n\n  &.pane {\n    padding: 1rem 2rem;\n  }\n\n  .title {\n    font-weight: lighter;\n    font-size: 1.6rem;\n    text-align: left;\n\n    em {\n      font-style: normal;\n      font-weight: normal;\n    }\n  }\n  .description {\n    color: var(--color-elevated-text);\n    font-size: 1rem;\n    text-align: left;\n  }\n  .today {\n    color: var(--color-base-text);\n    font-size: 0.9rem;\n    border: 1px solid var(--color-elevated-hint);\n    padding: 1rem;\n    text-align: left;\n\n    code {\n      color: var(--color-elevated-text);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
