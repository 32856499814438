import debug from "debug";

// debug.formatters.J = (jcs) => {
//   return jcs.name || jcs.id;
// };

// debug.formatters.L = (o) => {
//   return (o) ? o.length.toLocaleString() : 0;
// };

// debug.formatters.S = (spec) => {
//   return JSON.stringify(spec);
// };

// const debug = () => {};
export {debug};
