import {jobCompoundType} from "./job_compound_type.js";
import {formatEnumerated} from "@/library/format/format_enumerated.js";


export function jobStatusMessage(intl, job) {
  const status = (!job.exit || job.exit === "normal") ? job.status : job.exit;
  return formatEnumerated(
    intl,
    `jobs.job.${jobCompoundType(job)}.status`,
    status,
    {exit: job.exit, status: job.status}
  );
}
