import * as chrono from "chrono-node";

import {regexFromUser} from "@/library/utility/regex_from_user.js";

export function filterJobs(all, search, controlServers, users) {
  if (!search) {
    return all;
  }

  const filterExp = regexFromUser(search);
  const parsed = chrono.parse(search)[0];
  let from = null;
  let to = null;
  if (parsed?.start && parsed?.end) {
    from = parsed.start.date().valueOf();
    to = parsed.end.date().valueOf();
  } else if (parsed?.start) {
    from = parsed.start.date().valueOf();
  } else if (parsed?.end) {
    to = parsed.end.date().valueOf();
  }

  return all.filter((j) => {
    const jobMin = j.receivedAt;
    const jobMax = Math.max(j.receivedAt, j.completedAt ?? 0, j.exitedAt ?? 0);
    if (from && to) {
      return jobMax - from <= 0 && to - jobMin >= 0;
    }
    if (from) {
      return jobMax - from <= 0;
    }
    if (to) {
      return to - jobMin >= 0;
    };

    if (filterExp.test(j.name)
        || filterExp.test(j.type)
        || filterExp.test(j.status)
        || filterExp.test(j.source)
        || filterExp.test(j.id)
        || filterExp.test(j.sourceId)
        || filterExp.test(j.sourceExtendedId)
        || j.errors?.find(e => filterExp.test(e.extensions?.id))) {
      return true;
    }

    const controlServer = controlServers.find(c => c.id === j.controlServer?.id);
    if (controlServer) {
      if (filterExp.test(controlServer.identifier)
          || filterExp.test(controlServer.id)) {
        return true;
      }
    }

    const createdBy = users.find(u => u.id === j.createdBy?.id);
    if (createdBy) {
      if (filterExp.test(createdBy.fullName)
          || filterExp.test(createdBy.identifier)
          || filterExp.test(createdBy.email)
          || filterExp.test(createdBy.id)) {
        return true;
      }
    }

    return false;
  });
}
