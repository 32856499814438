export function groupCompilers(hydratedCompilers) {
  return Object.values(hydratedCompilers.reduce((r, c) => {
    r[c.groupIdentifier] ||= {};
    r[c.groupIdentifier].identifier ||= c.groupIdentifier;

    r[c.groupIdentifier].__typename = c.__typename;
    r[c.groupIdentifier].available ||= c.available;
    r[c.groupIdentifier].availableAt ||= c.availableAt;
    r[c.groupIdentifier].jobQueue ||= {queued: []};
    r[c.groupIdentifier].jobQueue.queued = Array.from(
      (new Set(r[c.groupIdentifier].jobQueue?.queued || [])).add(c.jobQueue?.queued || [])
    );
    return r;
  }, {}));
}
