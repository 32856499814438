import localForage from "localforage";
import React from "react";
import {injectIntl} from "react-intl";

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "./constants.js";


class Boundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmingReset: false,
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    const handleReset = () => {
      if (this.state.confirmingReset) {
        this.props.dispatch({type: "CLEAR"});
        return;
      }
      this.setState({confirmingReset: true});
      setTimeout(
        () => this.setState({confirmingReset: false}), CONFIRM_DESTRUCTIVE_TIMEOUT_MS
      );
    };

    const buttonMessageId = (this.state.confirmingReset)
      ? "error.navigation.reset.confirm"
      : "error.navigation.reset.button";

    let className = "error-boundary navigation pane";
    if (this.state.confirmingReset) {
      className += " confirming-reset";
    }
    return (
      <div className={className}>
        <div className="error-label">
          {this.props.intl.formatMessage({id: "error.navigation.label"})}
        </div>
        <div className="error-message">
          {this.state.error.message}
        </div>
        {this.state.error.id && (
          <div className="error-id">
            {this.props.intl.formatMessage({id: "error.navigation.id"}, {id: this.state.error.id})}
          </div>
        )}
        <button className="error-reset-button" onClick={handleReset}>
          {this.props.intl.formatMessage({id: buttonMessageId})}
        </button>
      </div>
    );
  }
}

export const NavigationErrorBoundary = injectIntl(Boundary);
