import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";

import {ERROR_VISUAL_TIMEOUT_MS} from "@/constants.js";
import {SERVICE_UPDATE_VISUAL_TIMEOUT_MS} from "@/constants.js";

export function ServiceCallButton({className, disabled, messageId, onClick}) {
  const intl = useIntl();

  const showErrorOnButtonTimeout = useRef(null);
  const showErrorTimeout = useRef(null);
  const workingTimeout = useRef(null);

  const [working, setWorking] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showErrorOnButton, setShowErrorOnButton] = useState(false);

  useEffect(() => () => {
    clearTimeout(showErrorTimeout.current);
    clearTimeout(showErrorOnButtonTimeout.current);
  }, []);

  const handleClick = async (event) => {
    workingTimeout.current = setTimeout(() => {
      setWorking(false);
    }, SERVICE_UPDATE_VISUAL_TIMEOUT_MS);
    setWorking(true);

    try {
      setShowErrorOnButton(false);
      setError(null);
      await onClick(event);

    } catch (e) {
      console.error(e);
      setError(e);

      clearTimeout(showErrorTimeout.current);
      showErrorTimeout.current = setTimeout(() => {
        setShowError(false);
      }, ERROR_VISUAL_TIMEOUT_MS * 2);
      setShowError(true);

      clearTimeout(showErrorOnButtonTimeout.current);
      showErrorOnButtonTimeout.current = setTimeout(() => {
        setShowErrorOnButton(false);
      }, ERROR_VISUAL_TIMEOUT_MS);
      setShowErrorOnButton(true);

    } finally {
      setWorking(false);
    }

  };

  let currentClassName = className;
  let currentDisabled = disabled;
  let currentMessageId = messageId;

  if (showError) {
    currentClassName += " error focused";
  }

  if (showErrorOnButton) {
    currentMessageId += ".error.label";
    currentDisabled = true;

  } else if (working) {
    currentClassName += " working focused";
    currentMessageId += ".working";

  }

  return (
    <React.Fragment>
      <button className={className} disabled={currentDisabled} onClick={handleClick}>
        {intl.formatMessage({id: currentMessageId})}
      </button>

      {showError && (
        <div className="error-popover-for-button">
          {intl.formatMessage({id: `${messageId}.error`}, {errorId: error.id})}
        </div>
      )}
    </React.Fragment>
  );
}
