import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";
import {selectFlat} from "@/data/select_flat.js";
import {RoleCard} from "./role_card.jsx";
import {RoleCardNew} from "./role_card_new.jsx";
import {visibleRole} from "./visible_role.js";
import {SettingToggleButton} from "@/library/controls/setting_toggle_button.jsx";
import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";

export function RolesPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const includeArchived = useSelector(selectAppSetting("roles.includeArchived"));
  const showNewCardAlways = useSelector(selectAppSetting("roles.showNewCardAlways"));
  const showNewCardOnce = useSelector(selectAppSetting("roles.showNewCardOnce"));

  const showNewCard = showNewCardAlways || showNewCardOnce;

  const selectVisibleRoles = createSelector(
    selectFlat("role"), ar => ar.filter(visibleRole)
  );
  const selectFilteredRoles = createSelector(
    selectVisibleRoles, ar => ar.filter(u => includeArchived || !u.archivedAt)
  );
  const selectPermissionedRoles = createSelector(
    selectFilteredRoles,
    selectFlat("permission"),
    (fr, ap) => fr.map(r => Object.assign({}, r, {
      permissions: r.permissions.map(rp => ap.find(p => p.id === rp.id))
    }))
  );
  const selectOrderedRoles = createSelector(
    selectPermissionedRoles, pr => _.orderBy(pr, "identifier", "asc")
  );
  const roles = useSelector(selectOrderedRoles);
  const sessions = useSelector(s => s.environmentSession);

  useEffect(
    loadAndUnloadArchivable("Role", dispatch, sessions, includeArchived),
    [includeArchived]
  );

  return (
    <div className="roles-pane pane focusable-collection">
      <div className="pane-top">
        <h1 className="roles-title pane-title">
          {intl.formatMessage({id: "roles.title"})}
        </h1>
        <ActionsTile pane="roles" className="pane-actions">
          {!showNewCardAlways && (
            <SettingToggleButton className="show-new-card-once" setting="showNewCardOnce"/>
          )}
        </ActionsTile>
        <SettingsTile pane="roles">
          <SettingToggleCheckbox setting="includeArchived"/>
          <SettingToggleCheckbox setting="showNewCardAlways"/>
        </SettingsTile>
      </div>
      <div className="roles-list">
        {showNewCard && (
          <RoleCardNew/>
        )}
        {roles.map(role => (
          <RoleCard key={role.id} role={role} roles={roles}/>
        ))}
      </div>
    </div>
  );
}
