import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";
import {selectFilter} from "@/data/select_filter.js";
import {selectFlat} from "@/data/select_flat.js";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";

import {OrganizationNewTile} from "./organization_new_tile.jsx";
import {OrganizationCard} from "./organization_card.jsx";
import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {SettingToggleButton} from "@/library/controls/setting_toggle_button.jsx";

export function OrganizationsPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const showNewCardAlways = useSelector(selectAppSetting("organizations.showNewCardAlways"));
  const showNewCardOnce = useSelector(selectAppSetting("organizations.showNewCardOnce"));

  const showNewCard = showNewCardAlways || showNewCardOnce;

  const selectIncludeArchived = selectAppSetting("organizations.includeArchived");
  const selectFilterArchived = createSelector(selectIncludeArchived, ia => !ia);
  const selectOrganizations = selectFilter(
    selectFlat("organization"), selectFilterArchived, "archivedAt"
  );
  const selectManageableOrganizations = createSelector(
    selectOrganizations, us => us.filter(u => u.identifier !== "azure_quantum")
  );
  const organizations = useSelector(selectManageableOrganizations);
  const sessions = useSelector(s => s.environmentSession);
  const includeArchived = useSelector(selectIncludeArchived);

  useEffect(
    loadAndUnloadArchivable("Organization", dispatch, sessions, includeArchived),
    [includeArchived]
  );


  return (
    <div className="organizations-pane pane focusable-collection">
      <div className="pane-top">
        <h1 className="organizations-title pane-title">
          {intl.formatMessage({id: "organizations.title"})}
        </h1>

        <ActionsTile pane="organizations" className="pane-actions">
          {!showNewCardAlways && (
            <SettingToggleButton className="show-new-card-once" setting="showNewCardOnce"/>
          )}
        </ActionsTile>

        <SettingsTile pane="organizations">
          <SettingToggleCheckbox setting="includeArchived"/>
          <SettingToggleCheckbox setting="showNewCardAlways"/>
        </SettingsTile>
      </div>

      <div className="organizations-list">
        {showNewCard && (
          <OrganizationNewTile/>
        )}
        {organizations.map(organization => (
          <OrganizationCard key={organization.id} organization={organization}/>
        ))}
      </div>
    </div>
  );
}
