export const controlServerRequest = [
  {
    archivedAt: true,
    availableAt: true,
    calibrations: {
      id: true,
      createdAt: true,
      couplerCalibrations: {identifier: true, calibratedAt: true, eSwapEpg: true, swapEpg: true},
      qubitCalibrations: {identifier: true, calibratedAt: true, xEpg: true, xo2Epg: true}
    },
    commandStatus: true,
    jobQueue: {id: true},
    jobTermination: true,
    lockedAt: true,
    lockedBy: {id: true},
    publicKey: true
  },
  {"controlServers": {archived: false}, "controlServers.calibrations": {limit: 10}}
];
