import _ from "lodash";
import {createEnvironmentSlice} from "@/data/create_environment_slice.js";

export const {reducer, replace, update} = createEnvironmentSlice("Artifact", {
  extraReducers: (builder) => builder
    .addCase("sessions/deauthenticate", (state, action) => _.omit(state, action.payload.url))
    .addCase("Job/replace", (state, action) => {
      action.payload.forEach(job => job.artifacts.forEach(a => {
        state[action.session.url] ||= {};
        state[action.session.url][a.id] = Object.assign({}, a, {job: {id: job.id}});
      }));
    })
});
