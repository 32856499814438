import Aqumen from "@aqumen/sdk";
import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {setPreferences} from "@/data/preferences/preferences_slice.js";

export function AccountSettingCheckbox(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const settings = useSelector(s => s.preference);
  const session = useSelector(s => s.accessControlSession);
  const user = useSelector(s => s.accessControlSession.user);

  const handleChange = async () => {
    const changed = Object.assign(
      {}, settings, {[props.setting]: !settings[props.setting]}
    );
    dispatch(setPreferences(changed));
    await Aqumen.User.update(
      session, {id: user.id}, {preferences: JSON.stringify(changed)}
    );
  };

  const checked = Boolean(settings[props.setting]);
  let className = `account-preference checkbox ${_.kebabCase(props.setting)}`;
  if (checked) {
    className += " checked";
  }
  return (
    <div className={className}>
      <label className="account-preference label">
        <input type="checkbox" checked={checked} onChange={handleChange}/>
        <span className="account-preference label-text">
          {intl.formatMessage({id: "account.settings." + props.setting})}
        </span>
      </label>
    </div>
  );
};
