import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {setUserInterface} from "@/data/user_interface/user_interface_slice.js";

export function About() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aboutOpen = useSelector(s => s.userInterface.aboutOpen);
  if (!aboutOpen) {
    return null;
  }

  const handleClose = () => dispatch(setUserInterface({"aboutOpen": false}));
  const noop = e => e.stopPropagation();

  let releaseNotes = [];
  let i = 0;
  while (true) {
    i++;
    const title = intl.formatMessage({id: `about.version.${i}.title`, defaultMessage: "noMatch"});
    if (title === "noMatch") {
      break;
    }
    releaseNotes.push(
      <div className="version" key={i}>
        <div className="version-title">
          {title}
        </div>
        <div className="version-overview">
          {intl.formatMessage({id: `about.version.${i}.overview`}, {p: c => (<p>{c}</p>)})}
        </div>
        <ul className="version-details">
          {intl.formatMessage({id: `about.version.${i}.details`}, {l: c => (<li>{c}</li>)})}
        </ul>
      </div>
    );
  }

  return (
    <div className="about-backdrop" onClick={handleClose}>
      <div className="about" onClick={noop}>
        <div className="about-title">
          {intl.formatMessage({id: "about.product"}, {product: c => (<span className="product">{c}</span>)})}
        </div>
        <div className="copyright">
          {intl.formatMessage({id: "about.copyright"})}
        </div>
        <div className="about-content">
          <div className="current">
            {releaseNotes.pop()}
          </div>

          <h2 className="known-issues-title">
            {intl.formatMessage({id: "about.knownIssues.title"})}
          </h2>

          <ul className="known-issues-list">
            {intl.formatMessage({id: "about.knownIssues.items"}, {l: c => (<li>{c}</li>)})}
          </ul>

          <div className="previous">
            {releaseNotes.reverse()}
          </div>
        </div>
        <div className="about-actions">
          <button className="about-close-button" onClick={handleClose}>
            {intl.formatMessage({id: "about.close.button"})}
          </button>
        </div>
      </div>
    </div>
  );
}
