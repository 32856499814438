import React from "react";

import {UserCard} from "./user_card.jsx";

export function UsersList(props) {
  return (
    <React.Fragment>
      {props.users.map(u =>
        <UserCard key={"u-" + u.id} user={u}/>
      )}
    </React.Fragment>
  );
}
