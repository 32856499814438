import {accessControlUrl} from "./access_control_url.js";
import {accessControlHeaders} from "./access_control_headers.js";

export function accessControlRequest(path, payload = {}) {
  return fetch(accessControlUrl(path), {
    method: "POST",
    credentials: "include",
    headers: accessControlHeaders(),
    body: JSON.stringify(payload)
  });
}
