import {createSlice} from "@reduxjs/toolkit";

import {simpleYmd} from "@/library/utility/simple_ymd.js";

const slice = createSlice({
  name: "today",
  initialState: null,
  reducers: {
    "setToday": () => simpleYmd()
  }
});

export const {setToday} = slice.actions;
export const {reducer} = slice;
