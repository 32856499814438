import React, {cloneElement, isValidElement, useState} from "react";
import {useIntl} from "react-intl";

import IconSettings from "assets/icons/icon_settings.svg";

export function SettingsTile(props) {
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const titleMessageId = props.pane + ".settings.title";
  const ariaMessageId = props.pane + ".settings.button.aria";

  const handleClickSettings = () => {
    setOpen(!open);
  }

  let className = "settings-tile";
  className += (open) ? " open" : " closed";

  return (
    <div className={className}>
      <div className="settings-backdrop" onClick={handleClickSettings}></div>
      <div className="settings-container">
        <button className="settings-button" onClick={handleClickSettings}>
          <IconSettings aria-label={intl.formatMessage({id: ariaMessageId})}/>
        </button>

        <div className="settings-popover">
          <h2 className="settings-title" onClick={handleClickSettings}>
            {intl.formatMessage({id: titleMessageId})}
          </h2>
          {
            React.Children.map(
              props.children, c => (isValidElement(c)) ? cloneElement(c, {...props}) : c
            )
          }
        </div>
      </div>
    </div>
  );
}
