import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {ServiceCallButton} from "@/library/controls/service_call_button.jsx";

function emptySimulator() {
  return {identifier: ""};
}

const MIN_LENGTH = {
  identifier: 3,
  url: 15
};

export function SimulatorCardNew(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({identifier: true});
  const [invalids, setInvalids] = useState({identifier: false});
  const [simulator, setSimulator] = useState(emptySimulator());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChange = (ev) => {
    setSimulator(Object.assign({}, simulator, {[ev.target.name]: ev.target.value}));
    if (ev.target.value.length > 0
        && ev.target.value.trim().length < MIN_LENGTH[ev.target.name]) {
      setInvalids({...invalids, [ev.target.name]: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, [ev.target.name]: true});
      setInvalids({...invalids, [ev.target.name]: false});
    } else {
      setBlanks({...blanks, [ev.target.name]: false});
      setInvalids({...invalids, [ev.target.name]: false});
    }
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      await Aqumen.Simulator.create(
        session, Aqumen.Utility.create("Simulator", session, simulator)
      );
      setSimulator(emptySimulator());

    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "simulator-card new focusable-item");
  if (working) {
    className += " working";
  }
  return (
    <div className={className}>
      <h1 className="simulator-card-new-title">
        {intl.formatMessage({id: "operations.simulators.new.title"})}
      </h1>
      <div className="simulator-card-inputs">
        <label className="simulator-identifier">
          <span className="label-text">
            {intl.formatMessage({id: "operations.simulators.new.identifier"})}
          </span>
          <input className="simulator-identifier"
                 placeholder={intl.formatMessage({id: "operations.simulators.new.identifier.placeholder"})}
                 type="text"
                 disabled={working}
                 name="identifier"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={simulator.identifier}/>
        </label>
        <label className="simulator-group-identifier">
          <span className="label-text">
            {intl.formatMessage({id: "operations.simulators.new.groupIdentifier"})}
          </span>
          <input className="simulator-group-identifier"
                 placeholder={intl.formatMessage({id: "operations.simulators.new.groupIdentifier.placeholder"})}
                 type="text"
                 disabled={working}
                 name="groupIdentifier"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={simulator.groupIdentifier}/>
        </label>
        <label className="simulator-url">
          <span className="label-text">
            {intl.formatMessage({id: "operations.simulators.new.url"})}
          </span>
          <input className="simulator-url"
                 placeholder={intl.formatMessage({id: "operations.simulators.new.url.placeholder"})}
                 type="text"
                 disabled={working}
                 name="url"
                 onChange={handleChange}
                 onKeyUp={blurOnEnter}
                 value={simulator.url}/>
        </label>
      </div>

      <div className="simulator-card-meta">
        <div className="simulator-card-actions">
          <div className="simulator-card-action">
            <ServiceCallButton
              className="simulator-create"
              disabled={disabled}
              messageId="operations.simulators.new.create.button"
              onClick={handleCreate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
