import React from "react";
import {useIntl} from "react-intl";

import {InputText} from "@/library/controls/input_text.jsx";

export function SimulatorInputsTile({confirming, simulator, onChange, validator}) {
  const intl = useIntl();

  return (
    <div className="simulator-inputs-tile">
      <InputText className="simulator-identifier"
        disabled={confirming || simulator.archivedAt}
        messageId="operations.simulators.simulator.identifier"
        name="identifier"
        onChange={onChange}
        validator={validator}
        value={simulator.identifier}
      />
      <InputText className="simulator-group-identifier"
        disabled={confirming || simulator.archivedAt}
        messageId="operations.simulators.simulator.groupIdentifier"
        name="groupIdentifier"
        onChange={onChange}
        validator={validator}
        value={simulator.groupIdentifier}
      />
      <InputText className="simulator-url"
        disabled={confirming || simulator.archivedAt}
        messageId="operations.simulators.simulator.url"
        name="url"
        onChange={onChange}
        validator={validator}
        value={simulator.url}
      />
    </div>
  );
}
