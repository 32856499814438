import {createSelector} from "@reduxjs/toolkit";

export const selectWithSessions = (typeName) => createSelector(
  s => s.environmentSession,
  s => s[typeName],
  (sessions, entitiesBySession) => {
    return Object.keys(sessions).reduce((all, sessionUrl) => {
      return all.concat(Object.values(entitiesBySession[sessionUrl]).map(
        (entity) => ({...entity, session: sessions[sessionUrl]})
      ));
    }, []);
  }
);
