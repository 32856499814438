export const metricRequest = [{
  id: true,
  version: true,
  compiler: {id: true},
  controlServer: {id: true},
  organization: {id: true},
  simulator: {id: true},
  user: {id: true},
  identifier: true,
  value: true
}];
