import Aqumen from "@aqumen/sdk";

import {setPreferences} from "./preferences_slice.js";

export const togglePreference= (key) => (dispatch, getState) => {
  const state = getState();
  const session = state.accessControlSession;

  const previous = state.preference ?? {};
  const next = Object.assign({}, previous, {[key]: !previous[key]});

  dispatch(setPreferences(next));
  return Aqumen.User.update(
    session, {id: session.user.id}, {preferences: JSON.stringify(next)}
  );
};
