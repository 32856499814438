import _ from "lodash";
import Aqumen from "@aqumen/sdk";

import {payload} from "./payload.js";
import {Query} from "./query.js";
import {ACCESS_CONTROL_ENTITIES} from "./access_control_entities.js";

export function loadOneType(entityName, action, dispatch, sessions, filter = {}) {
  const selection = Query[entityName].selection;
  const merged = _.merge(structuredClone(Query[entityName].filter), filter);
  const onceOnly = ACCESS_CONTROL_ENTITIES.includes(entityName);

  let fetches = [];
  for (let key of Object.keys(sessions)) {
    fetches.push((async () => {
      let result;
      try {
        result = await Aqumen[entityName].all(sessions[key], selection, merged);
      } catch (e) {
        if (e?.code === 401 || e?.code === 403) {
          dispatch({type: "CLEAR"});
          // FIXME clear connections too
        }
        throw e;
      }
      dispatch(payload(action, sessions[key], result));
    })());
    if (onceOnly) {
      break;
    }
  }

  return Promise.all(fetches);
}
