import React from "react";

export function JobHistoryTile(props) {
  const job = props.job;
  if (_.isEmpty(job.history)) {
    return null;
  }
  const entries = job.history.map(h => {
    const history = JSON.parse(h);
    return {
      timestamp: history[0],
      formattedTimestamp: new Date(history[0]).toISOString().match(/^.*\dT(\d.*)Z$/)[1] + " UTC",
      action: _.upperCase(history[1]),
      message: history[2]
    };
  }).sort((a, b) => a.timestamp - b.timestamp);

  return (
    <div className="job-history-tile tile">
      {entries.map(h => (
        <div key={job.id + h.timestamp.toString() + h.action} className="job-history-entry">
          <span className="history-timestamp">{h.formattedTimestamp}</span>
          <span className="history-action">{h.action}</span>
          <span className="history-message">{h.message}</span>
        </div>
      ))}
    </div>
  );
}
