import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {selectFlat} from "@/data/select_flat.js";

export function UserOrganizationTile(props) {
  const intl = useIntl();

  const selectOrderedOrganizations = createSelector(
    selectFlat("organization"), o => _.orderBy(o, "identifier", "asc")
  );
  const organizations = useSelector(selectOrderedOrganizations);

  return (
    <div className="user-organization-tile">
      <label className="user-organization label">
        <span className="user-organization label-text">
          {intl.formatMessage({id: props.messagePrefix + ".organization.label"})}
        </span>
        <select className="user-organization-select"
                disabled={props.disabled}
                onChange={props.onChange}
                value={props.user.organization?.id || ""}>
          <option key="null" value="">
            {intl.formatMessage({id: props.messagePrefix + ".organization.options.none"})}
          </option>
          {organizations.map(o => (
            <option key={o.id} value={o.id}>
              {o.archivedAt ? intl.formatMessage({id: props.messagePrefix + ".organization.options.archived"}, o) : o.identifier}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}
