import _ from "lodash";
import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "EnvironmentSession",
  initialState: {},
  reducers: {
    "authenticateEnvironment": (state, action) => {
      state[action.payload.url] = action.payload;
    },
    "deauthenticateEnvironment": (state, action) => _.omit(state, action.payload.url)
  }
});

export const {authenticateEnvironment, deauthenticateEnvironment} = slice.actions;
export const {reducer} = slice;
