import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React from "react";
import {useIntl} from "react-intl";

import {formatNumber} from "@/library/format/format_number.js";
import IconStatusCircle from "assets/icons/icon_status_circle.svg";


export function SystemCommonDisplay(props) {
  const intl = useIntl();
  const type = _.camelCase(props.system.__typename);

  let status = props.system.status;
  status = status ?? ((props.system.available) ? "available" : "offline");
  const isOnline = props.system.available && status !== "paused";

  const className = `system-common-display ${status} ${props.className ?? ""}`;
  const showQueue = props.system.__typename === "ControlServer"
      && (props.system.available || (props.system.jobQueue?.queued?.length ?? 0) > 0);

  const identifier = props.system.groupIdentifier || props.system.identifier;

  return (
    <div className={className}>
      <h2 className="system-identifier identifier">
        {identifier}
      </h2>

      <div className="system-availability">
        <span className="data-label">
          {intl.formatMessage({id: `systems.${type}.availability.label`})}
        </span>
        <span className="data-value">
          {intl.formatMessage({id: `systems.${type}.availability.${status}`})}
          {isOnline && (
            <IconStatusCircle/>
          )}
        </span>
      </div>

      {props.showEnvironment && (
        <div className="system-environment">
          {Aqumen.Utility.systemIdentifier(props.system.session)}
        </div>
      )}

      {showQueue && (
        <div className="system-job-queue">
          <span className="data-label">
            {intl.formatMessage({id: `systems.${type}.queueDepth.label`})}
          </span>
          <span className="data-value">
            {formatNumber(props.system.jobQueue?.queued?.length)}
          </span>
        </div>
      )}

    </div>
  );
}
