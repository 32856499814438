import React from "react";
import {useIntl} from "react-intl";

import {InputCheckbox} from "@/library/controls/input_checkbox.jsx";
import {InputText} from "@/library/controls/input_text.jsx";

export function ControlServerInputsTile({confirming, controlServer, onChange, validator}) {
  const intl = useIntl();

  return (
    <div className="control-server-inputs-tile">
      <InputText className="control-server-identifier"
        disabled={confirming || controlServer.archivedAt}
        messageId="operations.controlServers.controlServer.identifier"
        name="identifier"
        onChange={onChange}
        validator={validator}
        value={controlServer.identifier}
      />
      <InputText className="control-server-public-key"
        disabled={confirming || controlServer.archivedAt}
        messageId="operations.controlServers.controlServer.publicKey"
        name="publicKey"
        onChange={onChange}
        validator={validator}
        value={controlServer.publicKey}
      />
      <fieldset>
        <legend>
          {intl.formatMessage({id: "operations.controlServers.controlServer.jobTermination"})}
        </legend>
        <InputCheckbox className="control-server-job-termination-restart"
          checked={controlServer.jobTermination?.includes("restart")}
          disabled={confirming || controlServer.archivedAt}
          messageId="operations.controlServers.controlServer.jobTermination.restart"
          name="jobTermination"
          onChange={onChange}
          validator={validator}
          value="restart"
        />
        <InputCheckbox className="control-server-job-termination-unavailable"
          disabled={confirming || controlServer.archivedAt}
          checked={controlServer.jobTermination?.includes("unavailable")}
          messageId="operations.controlServers.controlServer.jobTermination.unavailable"
          name="jobTermination"
          onChange={onChange}
          validator={validator}
          value="unavailable"
        />
      </fieldset>
    </div>
  );
}
