import localForage from "localforage";
import {createRoot} from "react-dom/client";

import {ONE_HOUR_MS} from "./constants.js";
import {rootProvider} from "./root_provider.jsx";

import "./style/index.js";

navigator.serviceWorker.register("service_worker.js", {scope: "./"}).then((r) => {
  console.debug("Index: service worker registered", r);
  setInterval(() => {
    r.update();
    console.debug("Index: service worker registration.update");
  }, ONE_HOUR_MS);
});

localForage.ready().then(async () => {
  console.debug("Index: LocalForage ready.", localForage);
  await localForage.setDriver(localForage.INDEXEDDB);
  console.debug("Index: LocalForage driver set.", localForage);
  const old = await localForage.getItem("persist:aqumen-oqulus");
  if (old) {
    await localForage.setItem("persist:aqumen-data", old);
    await localForage.removeItem("persist:aqumen-oqulus");
  }

  const metaTitle = Array.from(document.getElementsByTagName("meta")).find((e) => {
    return e.name === "apple-mobile-web-app-title";
  });
  metaTitle.content = process.env.APP_NAME;
  document.title = process.env.APP_NAME;

  const root = createRoot(document.getElementById("app-container"));
  rootProvider(root, localForage);
});
