import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {DisplayId} from "@/library/display/display_id.jsx";

import {ConfigurationCard} from "./configuration_card.jsx";

export function ConfigurationsPane() {
  const intl = useIntl();

  const configurations = useSelector(s => s.configuration);

  const findValue = (all, identifier) => {
    const c = Object.keys(all).find(k => all[k].identifier === identifier);
    return (c) ? all[c].value : undefined;
  };
  const cardKeys = (serviceUrl) => {
    const all = Object.keys(configurations[serviceUrl]);
    const filtered = all.filter(k => configurations[serviceUrl][k].identifier !== "public_key");
    return _.sortBy(filtered, "identifier");
  };
  return (
    <div className="configurations-pane pane focusable-collection">
      <div className="pane-top">
        <div className="configurations-title pane-title">
          {intl.formatMessage({id: "configurations.title"})}
        </div>
      </div>
      {Object.keys(configurations).toSorted().map(serviceUrl => (
        <div key={serviceUrl}>
          <div className="instance-name">
            {intl.formatMessage(
              {id: "configurations.instance.name"},
              {hostname: Aqumen.Utility.hostname(serviceUrl), name: c => (<span className="name">{c}</span>)}
            )}
          </div>
          <div className="instance-public-key">
            {intl.formatMessage({id: "configurations.instance.key"})}
            <DisplayId className="key" truncate={false} id={findValue(configurations[serviceUrl], "public_key")}/>
          </div>
          <table className="configuration-table" key={serviceUrl}>
            <thead>
              <tr>
                <th>Identifier</th>
                <th>Value</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <React.Fragment>
                {cardKeys(serviceUrl).map(k => (
                  <ConfigurationCard key={k} configuration={configurations[serviceUrl][k]}/>
                ))}
              </React.Fragment>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
