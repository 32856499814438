import _ from "lodash";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {selectFlat} from "@/data/select_flat.js";

import {NewUserTile} from "./new_user_tile.jsx";
import {OrganizationsList} from "./organizations_list.jsx";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";
import {UsersList} from "./users_list.jsx";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {SettingToggleButton} from "@/library/controls/setting_toggle_button.jsx";

export function UsersPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const includeArchived = useSelector(selectAppSetting("users.includeArchived"));
  const groupByOrganization = useSelector(selectAppSetting("users.groupByOrganization"));
  const showNewCardAlways = useSelector(selectAppSetting("users.showNewCardAlways"));
  const showNewCardOnce = useSelector(selectAppSetting("users.showNewCardOnce"));

  const showNewCard = showNewCardAlways || showNewCardOnce;

  const selectOrderedOrganizations = createSelector(
    selectFlat("organization"), o => _.orderBy(o, "identifier", "asc")
  );
  const organizations = useSelector(selectOrderedOrganizations);

  const selectFilteredUsers = createSelector(
    selectFlat("user"), us => us.filter(u => includeArchived || !u.archivedAt)
  );
  const selectManageableUsers = createSelector(
    selectFilteredUsers, us => us.filter(u => u.identifier !== "azure_quantum")
  );
  const selectOrderedUsers = createSelector(
    selectManageableUsers, u => _.orderBy(u, "fullName", "asc")
  );
  const users = useSelector(selectOrderedUsers);
  const sessions = useSelector(s => s.environmentSession);

  useEffect(
    loadAndUnloadArchivable("User", dispatch, sessions, includeArchived),
    [includeArchived]
  );

  let className = "users-pane pane focusable-collection";
  if (groupByOrganization) {
    className += " group-by-organizations";
  }

  return (
    <div className={className}>
      <div className="pane-top">
        <h1 className="users-title pane-title">
          {intl.formatMessage({id: "users.title"})}
        </h1>
        <ActionsTile pane="users" className="pane-actions">
          {!showNewCardAlways && (
            <SettingToggleButton className="show-new-card-once" setting="showNewCardOnce"/>
          )}
        </ActionsTile>
        <SettingsTile pane="users">
          <SettingToggleCheckbox setting="includeArchived"/>
          <SettingToggleCheckbox setting="groupByOrganization"/>
          <SettingToggleCheckbox setting="showNewCardAlways"/>
        </SettingsTile>
      </div>
      <div className="users-list">
        {showNewCard && (
          <NewUserTile/>
        )}
        {groupByOrganization && (
          <OrganizationsList organizations={organizations} users={users}/>
        )}
        {!groupByOrganization && (
          <UsersList users={users}/>
        )}
      </div>
    </div>
  );
}
