import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

export function JobTagsDisplay(props) {
  const job = props.job;
  const tags = job.tags;

  return (
    <span className="job-tags-display">
      {tags.map(t => (
        <span key={job.id + t} className="job-tag">{t}</span>
      ))}
    </span>
  );
}
