import Aqumen from "@aqumen/sdk";

import {Query} from "@/data/query.js";

export async function fetchControlServer(session, id) {
  const result = await Aqumen.ControlServer.all(
    session, Query.ControlServer.selection, {controlServers: {id, includeArchived: true}}
  );

  return result?.find(cs => cs.id === id);
}
