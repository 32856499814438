export const metadata = Object.freeze({
  typeName: "Configuration",
  properties: {
    default: {
      id: true,
      identifier: true,
      value: true
    }
  }
});
