import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";

export function AccountEmailTile(props) {
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const [email, setEmail] = useState(props.user.email ?? "");

  const submit = (value) => {
    Aqumen.User.update(session, {id: props.user.id}, {email: value});
  };
  const debouncedSubmit = useDebouncedCallback(submit, 1000);

  const handleChange = (event) => {
    setEmail(event.target.value);
    debouncedSubmit(event.target.value);
  };

  const handleBlur = (event) => {
    debouncedSubmit.cancel();
    if (event.target.value !== props.user.email) {
      return submit(event.target.value);
    }
  };

  const disabled = Boolean(props.user.archivedAt);
  return (
    <div className="account-email-tile">
      <label className="account-email label">
        <span className="account-email label-text">
          {intl.formatMessage({id: "account.properties.email.label"})}
        </span>
        <input className="account-email"
               type="email"
               value={email}
               disabled={disabled}
               onBlur={handleBlur}
               onChange={handleChange}
               onKeyUp={blurOnEnter}/>
      </label>
    </div>
  );
}
