import _ from "lodash";

import {websocket} from "./websocket.js";

export function ensureConnections({connections, dispatch, sessions, setConnections}) {
  if (_.isEmpty(connections) && !_.isEmpty(sessions)) {
    let c = {};
    sessions.forEach(async s => c[s.url] = await websocket(dispatch, s));
    setConnections(c);
  }
}
