import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {toggleAppSetting} from "@/data/preferences/toggle_app_setting.js";

export function SettingToggleCheckbox({pane, setting}) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const settingKey = `${pane}.${setting}`;
  const messageId = `${pane}.settings.${setting}.checkbox`;

  const checked = useSelector(
    createSelector(selectAppSetting(settingKey), p => Boolean(p))
  );

  const handleChange = () => dispatch(toggleAppSetting(settingKey));

  return (
    <label className="setting toggle label">
      <span className="setting toggle label-text">
        {intl.formatMessage({id: messageId})}
      </span>
      <input
        checked={checked}
        className="setting toggle checkbox"
        onChange={handleChange}
        type="checkbox"
      />
    </label>
  );
}
