import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

import {formatEnumerated} from "@/library/format/format_enumerated.js";
import {jobCompoundType} from "./job_compound_type.js";

export function JobTypeDisplay(props) {
  const job = props.job;
  const intl = useIntl();

  const controlServer = job.controlServer?.identifier ?? "unknown system";
  const environment = job.environmentName ?? "a custom environment";
  return (
    <span className="job-type-display">
      {formatEnumerated(intl, "jobs.job.type", jobCompoundType(job), {controlServer, environment})}
    </span>
  );
}
