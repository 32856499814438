import React from "react";
import {useIntl} from "react-intl";

import {AccountSettingCheckbox} from "./account_setting_checkbox.jsx";
import {AccountSettingRadio} from "./account_setting_radio.jsx";
import {TOGGLE_APP_SETTINGS} from "@/data/preferences/preferences_slice.js";

export function AccountPreferencesPanel(props) {
  const intl = useIntl();

  return (
    <div className="account-settings-panel preferences">
      <div className="account-settings-panel-title">
        {intl.formatMessage({id: "account.settings.preferences.title"})}
      </div>
      {Object.keys(TOGGLE_APP_SETTINGS).map(p => (
        <AccountSettingCheckbox key={p} setting={p}/>
      ))}
      <AccountSettingRadio
          setting="preferences.qwa.overrideColorScheme"
          local={true}
          values={["automatic", "dark", "light"]}/>
    </div>
  );
}
