import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {ArchiveButton} from "./archive_button.jsx";
import {DisplayId} from "@/library/display/display_id.jsx";
import {UserEmailTile} from "./user_email_tile.jsx";
import {UserFullNameTile} from "./user_full_name_tile.jsx";
import {UserOrganizationTile} from "./user_organization_tile.jsx";
import {UserTimestampsTile} from "./user_timestamps_tile.jsx";

export function UserCard(props) {
  const intl = useIntl();
  const [confirmingRegistration, setConfirmingRegistration] = useState(false);
  const [confirmingArchive, setConfirmingArchive] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleOrganizationChange = (event) => {
    let id = event.target.value || null;
    if (id !== props.user.organization?.id) {
      Aqumen.User.update(session, {id: props.user.id}, {organization: (id) ? {id} : null});
    }
  };

  let className = "user-card card focusable-item";
  let messagePrefix = "users.user";
  let disabledInputs = false;
  if (props.user.archivedAt) {
    className += " archived";
    messagePrefix += ".archived";
    disabledInputs = true;
  }
  if (confirmingArchive) {
    className += " confirming confirming-archive";
    disabledInputs = true;
  }
  if (confirmingRegistration) {
    className += " confirming confirming-registration";
    disabledInputs = true;
  }
  return (
    <div className={className}>
      <div className="user-card-inputs">
        <UserFullNameTile user={props.user}
          messagePrefix={messagePrefix}
          disabled={disabledInputs}/>
        <UserEmailTile user={props.user}
          messagePrefix={messagePrefix}
          disabled={disabledInputs}
          confirmingArchive={props.confirmingArchive}
          setConfirmingRegistration={setConfirmingRegistration}/>
        <UserOrganizationTile user={props.user}
          messagePrefix={messagePrefix}
          disabled={disabledInputs}
          onChange={handleOrganizationChange}/>
      </div>

      <div className="user-card-meta">
        <div className="user-card-ids">
          <div className="user-card-aqumen-id">
            <span className="data-label">
              {intl.formatMessage({id: `users.user.id.aqumen.label`})}
            </span>
            <span className="data-value">
              <DisplayId id={props.user.id} disabled={confirmingArchive}/>
            </span>
          </div>
          {false && props.user.azureCustomerSubscriptionId && (
            <div className="user-card-azure-id">
              <span className="data-label">
                {intl.formatMessage({id: "users.user.id.azure.label"})}
              </span>
              <span className="data-value">
                <DisplayId id={props.user.azureCustomerSubscriptionId} disabled={confirmingArchive}/>
              </span>
            </div>
          )}
          <UserTimestampsTile user={props.user}/>
        </div>
        <div className="user-card-actions">
          <ArchiveButton
            disabled={confirmingRegistration}
            messageId="users.user.button.archive"
            setConfirming={setConfirmingArchive}
            user={props.user}
          />
        </div>
      </div>
    </div>
  );
}
