import React from "react";
import {useIntl} from "react-intl";

import {formatTimestamp} from "@/library/format/format_timestamp.js";

export function TokenTimestampsDisplay(props) {
  const token = props.token;
  const intl = useIntl();

  let messageId = "tokens.token.timestamps";
  if (!token.lastIp) {
    messageId += ".unused";
  }
  if (props.expired) {
    messageId += ".expired";
  }
  return (
    <div className="token-last-used display">
      {intl.formatMessage(
        {id: messageId},
        {
          createdAt: formatTimestamp(token.createdAt),
          lastUsedAt: formatTimestamp(token.lastUsedAt),
          expiresAt: formatTimestamp(token.expiresAt),
          ip: token.lastIp,
          expiration: (c) => (<span className="expiration">{c}</span>)
        }
      )}
    </div>
  );
}
