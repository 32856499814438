import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";

export function TokenDescriptionTile(props) {
  const intl = useIntl();
  const token = props.token;

  const session = useSelector(s => s.accessControlSession);
  const [description, setDescription] = useState(token.description ?? "");

  const submit = (description) => Aqumen.Token.update(
    session, {id: token.id}, {description}
  );
  const debouncedSubmit = useDebouncedCallback(submit, 1000);

  const handleChange = async (event) => {
    setDescription(event.target.value);
    await debouncedSubmit(event.target.value);
  };

  const handleBlur = (event) => {
    debouncedSubmit.cancel();
    if (event.target.value !== token.description) {
      return submit(event.target.value);
    }
  };

  return (
    <div className="token-description-tile">
      <label className="token-description label">
        <span className="token-description label-text">
          {intl.formatMessage({id: "tokens.token.description.label"})}
        </span>
        <input className="token-description"
               type="text"
               value={description}
               onBlur={handleBlur}
               onChange={handleChange}
               onKeyUp={blurOnEnter}/>
      </label>
    </div>
  );
}
