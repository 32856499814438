import React, {useContext, useState} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl"

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "./constants.js";
import {ConnectionsContext} from "./context/connections_context.js";


export function SignOutButton(props) {
  const dispatch = useDispatch();
  const intl = useIntl()
  const {connections, setConnections} = useContext(ConnectionsContext);
  const [confirm, setConfirm] = useState(false);

  const signOut = () => {
    if (confirm) {
      Object.values(connections).forEach(ws => ws.close());
      dispatch({type: "CLEAR"});
      setConnections({});
      setConfirm(false);
      return;
    }

    setConfirm(true);
    setTimeout(() => setConfirm(false), CONFIRM_DESTRUCTIVE_TIMEOUT_MS);
  }

  let className = "button sign-out " + (props.className ?? "");
  let mid = props.messageId;

  if (confirm) {
    className += " confirm";
    mid += ".confirm";
  }

  return (
    <button className={className} onClick={signOut}>
      {intl.formatMessage({id: mid})}
    </button>
  );
}
