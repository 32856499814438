import React, {useState} from "react";
import {useIntl} from "react-intl";

export function InputCheckbox({checked, className, disabled, messageId, name, onChange, value}) {
  const intl = useIntl();
  const [inputChecked, setInputChecked] = useState(checked);

  const handleChange = (event) => {
    setInputChecked(!inputChecked);
    onChange(event);
  };

  return (
    <label className={className}>
      <input className="input-checkbox"
        disabled={disabled}
        checked={Boolean(inputChecked)}
        onChange={handleChange}
        name={name}
        type="checkbox"
        value={value}
      />
      <span className="label-text">
        {intl.formatMessage({id: messageId})}
      </span>
    </label>
  );
}
