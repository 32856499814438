import {combineReducers} from "redux";
import {reducer as router} from "@minimum/router";
import {reducer as tabbed} from "@minimum/tabbed";

import {reducer as accessControlSession} from "./data/access_control_session/access_control_session_slice.js";
import {reducer as agreement} from "./data/agreement/agreement_slice.js";
import {reducer as artifact} from "./data/artifact/artifact_slice.js";
import {reducer as calibration} from "./data/calibration/calibration_slice.js";
import {reducer as compiler} from "./data/compiler/compiler_slice.js";
import {reducer as configuration} from "./data/configuration/configuration_slice.js";
import {reducer as controlServer} from "./data/control_server/control_server_slice.js";
import {reducer as environmentSession} from "./data/environment_session/environment_session_slice.js";
import {reducer as filter} from "./data/filter/filter_slice.js";
import {reducer as jobQueue} from "./data/job_queue/job_queue_slice.js";
import {reducer as job} from "./data/job/job_slice.js";
import {reducer as metric} from "./data/metric/metric_slice.js";
import {reducer as organization} from "./data/organization/organization_slice.js";
import {reducer as permission} from "./data/permission/permission_slice.js";
import {reducer as preference} from "./data/preferences/preferences_slice.js";
import {reducer as reservation} from "./data/reservation/reservation_slice.js";
import {reducer as role} from "./data/role/role_slice.js";
import {reducer as simulator} from "./data/simulator/simulator_slice.js";
import {reducer as today} from "./data/today/today_slice.js";
import {reducer as user} from "./data/user/user_slice.js";
import {reducer as userInterface} from "./data/user_interface/user_interface_slice.js";

const combinedReducer = combineReducers({
  accessControlSession,
  agreement,
  artifact,
  calibration,
  compiler,
  configuration,
  controlServer,
  environmentSession,
  filter,
  job,
  jobQueue,
  metric,
  organization,
  permission,
  preference,
  reservation,
  role,
  router,
  simulator,
  tabbed,
  today,
  user,
  userInterface
});

export const rootReducer = (state, action) => {
  return combinedReducer((action.type === "CLEAR") ? undefined : state, action);
};
