import Aqumen from "@aqumen/sdk";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {selectFlat} from "@/data/select_flat.js";
import IconDownload from "assets/icons/icon_download.svg";
import {selectSessionPermissions} from "@/data/permission/select_session_permissions.js";

export function ButtonDownloadArtifact(props) {
  const intl = useIntl();
  let artifactType = props.targetType;
  if (artifactType === "result") {
    artifactType = (props.job.type === "compile") ? "jmz" : "resultJson";
  }
  const findArtifacts = (j) => j.artifacts?.filter(a => a.type === artifactType);

  let disabled = _.isEmpty(findArtifacts(props.job));

  const selectUsers = selectFlat("user");
  const selectCreatorUser = createSelector(
    selectUsers,
    us => us.find(u => u.id === props.job?.createdBy?.id) || {}
  );
  const currentUser = useSelector(s => s.accessControlSession.user);
  const creatorUser = useSelector(selectCreatorUser);

  let possibleCreatorPermissions;
  if (creatorUser.id === currentUser.id) {
    possibleCreatorPermissions = ["self", "sharesOrganization", "anyone"];
  } else if (currentUser.organization?.id && creatorUser.organization?.id === currentUser.organization?.id) {
    possibleCreatorPermissions = ["sharesOrganization", "anyone"];
  } else {
    possibleCreatorPermissions = ["anyone"];
  }

  const selectButtonVisibiltyPermission = createSelector(
    selectSessionPermissions,
    userPermissions => userPermissions.find(p =>
      p.target === "artifacts"
      && p.action === "read"
      && (p.targetType === null || p.targetType === artifactType)
    )
  );
  const selectAuthorizingPermission = createSelector(
    selectSessionPermissions,
    userPermissions => userPermissions.find(p =>
      p.target === "artifacts"
      && p.action === "read"
      && (p.targetType === null || p.targetType === artifactType)
      && possibleCreatorPermissions.includes(p.targetCreatedBy)
    )
  );

  const hasButtonVisibiltyPermission = useSelector(selectButtonVisibiltyPermission);
  const hasAllAuthorizingPermissions = useSelector(selectAuthorizingPermission);

  if (!hasButtonVisibiltyPermission) {
    return null;
  }

  if (!hasAllAuthorizingPermissions) {
    disabled = true;
  }

  const download = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    if (name) {
      link.download = name;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = async () => {
    const latest = await Aqumen.Job.all(
      props.job.session,
      {artifacts: {id: true, name: true, receivedAt: true, type: true, url: true}},
      {jobs: {id: props.job.id}}
    );
    const artifacts = findArtifacts(latest?.[0]);
    if (_.isEmpty(artifacts)) {
      console.error("artifact missing");
    } else if (artifacts.length === 1) {
      download(artifacts[0].url, artifacts[0].name);
    } else {
      const sorted = _.sortBy(artifacts, "receivedAt");
      const fetches = sorted.map(a => fetch(a.url).then(r => r.bytes()));
      const data = await Promise.all(fetches);
      const length = data.reduce((r, d) => r += d.length, 0);
      let lastIndex = 0;
      const joined = new Uint8Array(length);
      data.forEach(d => {
        joined.set(d, lastIndex);
        lastIndex += d.length;
      });
      download(URL.createObjectURL(new Blob(
        [joined], {type: "application/octet-stream"}
      )), sorted[0].name);
    }
  };

  const className = `download artifact ${props.targetType} ${props.className ?? ""}`;

  return (
    <button disabled={disabled} onClick={handleClick} className={className}>
      <IconDownload aria-label={intl.formatMessage({id: "aria.label.icon.download"})}/>
      <span className="button-text">{props.children}</span>
    </button>
  )
}
