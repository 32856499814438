import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {orderByAvailabilityAndIdentifier} from "@/library/utility/order_by_availability_and_identifier.js"
import {selectFlat} from "@/data/select_flat.js";
import {set as setFilter, clear as clearFilter} from "@/data/filter/filter_slice.js";

import {CompilerCard} from "./compiler_card.jsx";
import {ControlServerCard} from "./control_server_card.jsx";
import {groupCompilers} from "./group_compilers.js"
import {groupSimulators} from "./group_simulators.js"
import {hydrateCompilers} from "./hydrate_compilers.js"
import {hydrateControlServers} from "./hydrate_control_servers.js"
import {hydrateSimulators} from "./hydrate_simulators.js"
import {selectDoubleFlat} from "@/data/select_double_flat.js";
import {SimulatorCard} from "./simulator_card.jsx";
import {SystemErrorBoundary} from "./system_error_boundary.jsx";

// import {selectFakeControlServers} from "fake_data/select_fake_control_servers.js";

export function SystemsList(props) {
  const dispatch = useDispatch();

  const filters = useSelector(s => s.filter);
  const showEnvironment = useSelector(s => Object.keys(s.environmentSession).length > 1);

  const selectControlServers = selectDoubleFlat("controlServer");
  // const selectControlServers = selectFakeControlServers;

  const selectCompilers = selectDoubleFlat("compiler");
  const selectJobQueues = selectDoubleFlat("jobQueue");
  const selectReservations = selectDoubleFlat("reservation");
  const selectSimulators = selectDoubleFlat("simulator");
  const selectUsers = selectFlat("user");

  const selectFilteredCompilers = createSelector(
    selectCompilers, ac => ac.filter(c => !c.archivedAt && c.groupIdentifier)
  );
  const selectFilteredSimulators = createSelector(
    selectSimulators,
    as => as.filter(s => !s.archivedAt && s.groupIdentifier && !/(nvidia|qiskit)/.test(s.identifier))
  );
  const selectFilteredControlServers = createSelector(
    selectControlServers, acs => acs.filter(cs => !cs.archivedAt)
  );

  const selectHydratedCompilers = createSelector(
    selectFilteredCompilers, selectJobQueues, hydrateCompilers);
  const selectHydratedSimulators = createSelector(
    selectFilteredSimulators, selectJobQueues, hydrateSimulators
  );
  const selectHydratedControlServers = createSelector(
    selectFilteredControlServers, selectJobQueues, selectReservations, selectUsers, hydrateControlServers
  );

  const selectGroupedCompilers = createSelector(
    selectHydratedCompilers, groupCompilers
  );
  const selectGroupedSimulators = createSelector(
    selectHydratedSimulators, groupSimulators
  );

  const selectOrdered = createSelector(
    selectGroupedCompilers,
    selectHydratedControlServers,
    selectGroupedSimulators,
    orderByAvailabilityAndIdentifier
  );
  const systems = useSelector(selectOrdered);
  const activeSystemId = useSelector(s => s.filters?.systemDetail?.system?.id)

  if (systems.length === 0) {
    return null;
  }

  const handleItemClick = (system) => () => {
    if (filters?.systemDetail?.system?.id === system.id) {
      return dispatch(clearFilter({type: "systemDetail"}));
    }
    return dispatch(setFilter({type: "systemDetail", filter: {system}}));
  };

  return (
    <ul className={"systems-list list " + (props.className ?? "")}>
      {systems.map((system) => {
        const key = system.__typename + system.identifier;
        const isActive = activeSystemId === system.id;
        let itemClassName = "systems-item item";
        if (isActive) {
          itemClassName += " active";
        }
        return (
          <li className={itemClassName} key={key} onClick={handleItemClick(system)}>
            <SystemErrorBoundary system={system}>
              {system.__typename === "Compiler" && (
                <CompilerCard key={`compiler:${system.identifier}`} isActive={isActive} compiler={system} showEnvironment={showEnvironment}/>
              )}
              {system.__typename === "ControlServer" && (
                <ControlServerCard key={`control-server:${system.identifier}`} isActive={isActive} controlServer={system} showEnvironment={showEnvironment}/>
              )}
              {system.__typename === "Simulator" && (
                <SimulatorCard key={`simulator:${system.identifier}`} isActive={isActive} simulator={system} showEnvironment={showEnvironment}/>
              )}
            </SystemErrorBoundary>
          </li>
        );
      })}
    </ul>
  );
}
