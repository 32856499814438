import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "@/constants.js";

export function ArchiveButton(props) {
  const intl = useIntl();

  const [confirming, setConfirming] = useState(false);
  const session = useSelector(s => s.accessControlSession);

  const disabled = props.disabled || session.user.id === props.user.id;

  const toggleConfirming = () => {
    setConfirming(true);
    props.setConfirming?.(true);
    setTimeout(() => {
      setConfirming(false);
      props.setConfirming?.(false);
    },CONFIRM_DESTRUCTIVE_TIMEOUT_MS);
  };

  const toggleArchived = async () => {
    const archivedAt = (props.user.archivedAt) ? null : Date.now();
    await Aqumen.User.update(session, {id: props.user.id}, {archivedAt});
    props.setConfirming?.(false);
    setConfirming(false);
  };

  if (!props.user.archivedAt && !confirming) {
    return (
      <div className="user-archive">
        <button className="user-archive-button" onClick={toggleConfirming} disabled={disabled}>
          {intl.formatMessage({id: `users.user.archive.button`})}
        </button>
      </div>
    );
  }

  if (!props.user.archivedAt && confirming) {
    return (
      <div className="user-archive">
        <button className="user-archive-button confirming" onClick={toggleArchived} disabled={disabled}>
          {intl.formatMessage({id: `users.user.archive.confirm.button`})}
        </button>
      </div>
    );
  }

  if (props.user.archivedAt && !confirming) {
    return (
      <div className="user-archive">
        <button className="user-unarchive-button" onClick={toggleConfirming}>
          {intl.formatMessage({id: `users.user.unarchive.button`})}
        </button>
      </div>
    );
  }

  if (props.user.archivedAt && confirming) {
    return (
      <div className="user-archive confirming">
        <button className="user-unarchive-button confirming" onClick={toggleArchived}>
          {intl.formatMessage({id: `users.user.unarchive.confirm.button`})}
        </button>
      </div>
    );
  }
}
