import {selectFilter} from "./select_filter.js";
import {selectOrder} from "./select_order.js";
import {selectWithSessions} from "./select_with_sessions.js";

export const selectApplyCommon = (entityType, selectFilterArchived, orderBy = "identifier") =>
  selectOrder(
    selectFilter(
      selectWithSessions(entityType),
      selectFilterArchived,
      "archivedAt"
    ),
    orderBy, "asc"
  );
