import React, {useState} from "react";
import {useIntl} from "react-intl";

export function ConfigurationCard(props) {
  const intl = useIntl();

  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    setFocused(!focused);
  };

  let className = "configuration-card card";
  if (focused) {
    className += " focused";
  }
  return (
    <tr className={className} onClick={handleClick}>
      <td className="configuration-card-identifier">
        {props.configuration.identifier}
      </td>
      <td className="configuration-card-value">
        {props.configuration.value}
      </td>
      <td className="configuration-card-description">
        {intl.formatMessage({id: `configurations.${props.configuration.identifier}.description`})}
      </td>
    </tr>
  );
}
