import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";

import {centerTruncate} from "@/library/utility/center_truncate.jsx";
import {DISPLAY_SUCCESS_TIMEOUT_MS} from "@/constants.js";
import IconCopied from "assets/icons/icon_copied.svg";
import IconCopy from "assets/icons/icon_copy.svg";

export function DisplayId(props) {
  const intl = useIntl();
  const [copiedId, setCopiedId] = useState(false);
  const copiedIdTimeout = useRef(null);

  const handleCopy = () => {
    clearTimeout(copiedIdTimeout.current);
    navigator.clipboard.writeText(props.id);
    setCopiedId(true);
    copiedIdTimeout.current = setTimeout(
      () => setCopiedId(false), DISPLAY_SUCCESS_TIMEOUT_MS
    );
  };

  const truncate = props.truncate ?? true;
  const id = (truncate) ? centerTruncate(props.id) : props.id
  const className = `display-id ${(copiedId) ? "copied" : "copy"}`;
  return (
    <button className={className} onClick={handleCopy} disabled={props.disabled}>
      <span className="id-text uuid">{id}</span>
      {!copiedId && (
        <IconCopy aria-label={intl.formatMessage({id: "aria.label.icon.copy"})}/>
      )}
      {copiedId && (
        <IconCopied aria-label={intl.formatMessage({id: "aria.label.icon.copied"})}/>
      )}
    </button>
  );
}
