import React, {cloneElement, isValidElement} from "react";

export function ActionsTile(props) {
  return (
    <div className={`actions-tile ${props.className}`}>
      {
        React.Children.map(
          props.children, c => (isValidElement(c)) ? cloneElement(c, {...props, ...c.props}) : c
        )
      }
    </div>
  );
}
