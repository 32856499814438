import React from "react";

import {UsersList} from "./users_list.jsx";

export function OrganizationsList(props) {
  const organizations = (props.users.find(u => !u.organization?.id))
    ? [null].concat(props.organizations)
    : props.organizations
  ;
  return (
    <React.Fragment>
      {organizations.map((o) => {
        const memberUsers = props.users.filter(u => u.organization === o || u.organization?.id === o?.id);
        return (
          <React.Fragment key={o?.id || "no-org-id"}>
            <div className="organization-group">
              {o?.identifier || "No Organization"}
            </div>
            {memberUsers.length > 0 && (
              <UsersList users={memberUsers} reload={props.reload}/>
            )}
            {memberUsers.length <= 0 && (
              <div className="empty-organization">No Users</div>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
