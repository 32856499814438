import React from "react";
import {useIntl} from "react-intl";

import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";

export function TokenCreatedDisplay(props) {
  const token = props.token;
  const intl = useIntl();

  return (
    <div className="token-created display">
      <span className="token-created-label">
        {intl.formatMessage({id: "tokens.token.created.label"})}
      </span>
      <TimestampDisplay value={token.createdAt ?? token.receivedAt}/>
    </div>
  );
}
