import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

import {formatDuration} from "@/library/format/format_duration.js";

export function DashboardSystemUsageCard(props) {
  const intl = useIntl();

  const className = "dashboard-system-usage-card"
    + ((props.systemAvailable) ? " available" : " unavailable");

  return (
    <div className={className}>
      <div className="dashboard-system-usage-system-identifier">
        <span className="system-identifier">{props.systemIdentifier}</span>
        {props.systemAvailable && (
          <span className="system-availability">Online •</span>
        )}
        {!props.systemAvailable && (
          <span className="system-availability">Offline</span>
        )}
      </div>
      <div className="dashboard-system-usage-jobs">
        {intl.formatMessage(
          {id: `dashboard.systemUsage.jobs.${(props.metric["total.count"] > 1) ? "multiple" : "one"}`},
          {count: props.metric["total.count"]}
        )}
      </div><div className="dashboard-system-usage-time">
        {formatDuration(props.metric["total.time"])}
      </div>
    </div>
  );
}
