import React from "react";
import {useIntl} from "react-intl";

import {InputText} from "@/library/controls/input_text.jsx";

export function CompilerInputsTile({confirming, compiler, onChange, validator}) {
  const intl = useIntl();

  return (
    <div className="compiler-inputs-tile">
      <InputText className="compiler-identifier"
        disabled={confirming || compiler.archivedAt}
        messageId="operations.compilers.compiler.identifier"
        name="identifier"
        onChange={onChange}
        validator={validator}
        value={compiler.identifier}
      />
      <InputText className="compiler-group-identifier"
        disabled={confirming || compiler.archivedAt}
        messageId="operations.compilers.compiler.groupIdentifier"
        name="groupIdentifier"
        onChange={onChange}
        validator={validator}
        value={compiler.groupIdentifier}
      />
      <InputText className="compiler-url"
        disabled={confirming || compiler.archivedAt}
        messageId="operations.compilers.compiler.url"
        name="url"
        onChange={onChange}
        validator={validator}
        value={compiler.url}
      />
    </div>
  );
}
