import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {loadAllTypes} from "@/data/load_all_types.js";

import {DashboardJobsTile} from "./dashboard_jobs_tile.jsx";
import {DashboardQuickLinksTile} from "./dashboard_quick_links_tile.jsx";
import {DashboardReservationStatusTile} from "./dashboard_reservation_status_tile.jsx";
import {DashboardSystemsUsageTile} from "./dashboard_systems_usage_tile.jsx";

export function DashboardPane() {
  const sessions = useSelector(s => s.environmentSession);
  const dispatch = useDispatch();

  useEffect(() => {loadAllTypes("merge", dispatch, sessions)}, []);

  return (
    <div className="pane dashboard-pane">
      <DashboardReservationStatusTile/>
      <DashboardJobsTile/>
      <DashboardSystemsUsageTile/>
      <DashboardQuickLinksTile/>
    </div>
  );
}
