import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Preferences",
  initialState: {},
  reducers: {
    "loadPreferences": (_state, action) => {
      const preferences = (action.payload) ? JSON.parse(action.payload) : {};
      Object.keys(ALL_APP_FEATURES_AND_SETTINGS).forEach(p => {
        if (!Object.hasOwn(preferences, p)) {
          preferences[p] = ALL_APP_FEATURES_AND_SETTINGS[p];
        }
      });
      return preferences;
    },
    "setPreferences": (_state, action) => action.payload
  }
});

export const APP_FEATURES_PREFIX = "settings.qwa";
export const APP_SETTINGS_PREFIX = "settings.qwa";

export const ALL_APP_FEATURES = {
  [`${APP_FEATURES_PREFIX}.wip.show`]: false,
  [`${APP_FEATURES_PREFIX}.theme.colorsOne`]: false
};

export const ALL_APP_SETTINGS = {
  [`${APP_SETTINGS_PREFIX}.jobs.columns.creator`]: false,
  [`${APP_SETTINGS_PREFIX}.jobs.headerRow`]: false,
  [`${APP_SETTINGS_PREFIX}.jobs.timestamps.seconds`]: false,
  [`${APP_SETTINGS_PREFIX}.overrideColorScheme`]: "automatic"
};

export const TOGGLE_APP_SETTINGS = {
};

export const ALL_APP_FEATURES_AND_SETTINGS = Object.assign(
  {}, ALL_APP_FEATURES, ALL_APP_SETTINGS
);

export const {loadPreferences, setPreferences} = slice.actions;
export const {reducer} = slice;
