import _ from "lodash";
import React from "react";

import {formatDuration} from "@/library/format/format_duration.js";

export function DisplayDuration(props) {
  return (
    <span className="display-duration">
      {formatDuration(props.value)}
    </span>
  );
};
