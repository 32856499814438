import React from "react";

import {TokenCreatedDisplay} from "./token_created_display.jsx";
import {TokenDescriptionTile} from "./token_description_tile.jsx";
import {TokenExpiresDisplay} from "./token_expires_display.jsx";
import {TokenTimestampsDisplay} from "./token_timestamps_display.jsx";
import {TokenDeleteButton} from "./token_delete_button.jsx";
import {ONE_WEEK_MS} from "@/constants.js";

export function TokenCard(props) {
  const token = props.token;

  let className = "token-card card";
  let expired = false;
  let expiresSoon = false;
  if (token.expiresAt <= Date.now()) {
    className += " expired";
    expired = true;
  } else if (token.expiresAt < Date.now() + ONE_WEEK_MS) {
    className += " expires-soon";
    expiresSoon = true;
  }

  return (
    <div className={className}>
      <TokenDescriptionTile token={token} reload={props.reload}/>
      <div className="token-actions">
        <TokenDeleteButton token={token} reload={props.reload}/>
      </div>
      <TokenTimestampsDisplay token={token} expired={expired} expiresSoon={expiresSoon}/>
    </div>
  )
}
