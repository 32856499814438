import _ from "lodash";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {ButtonDownloadArtifact} from "./button_download_artifact.jsx";
import {ButtonSignal} from "./button_signal.jsx";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";

export function JobActionsTile(props) {
  const job = props.job;
  const intl = useIntl();

  const [signalling, setSignalling] = useState(false);
  const showCancelMany = useSelector(selectAppSetting("jobs.showCancelMany"));

  return (
    <div className="job-actions-tile">
      {!showCancelMany && (
        <ButtonSignal
          className="job-action terminate"
          disabled={job.exitedAt || signalling}
          signal="terminate"
          job={job}
          onClick={() => setSignalling(true)}
        >
          <span className="button-text">
            {intl.formatMessage({id: "jobs.job.button.signal.terminate"})}
          </span>
        </ButtonSignal>
      )}

      <ButtonDownloadArtifact className="job-action log" job={job} targetType="log">
        {intl.formatMessage({id: "jobs.job.button.download.log"})}
      </ButtonDownloadArtifact>

      <ButtonDownloadArtifact className="job-action result" job={job} targetType="result">
        {intl.formatMessage({id: "jobs.job.button.download.result"})}
      </ButtonDownloadArtifact>
    </div>
  );
}
