import Aqumen from "@aqumen/sdk";
import React, {useContext, useState} from "react";
import {useIntl} from "react-intl"
import {useDispatch, useSelector} from "react-redux";

import {FAKE_SENDING_DURATION_MS} from "@/constants.js";
import {toggleAppSetting} from "@/data/preferences/toggle_app_setting.js";
import {ConnectionsContext} from "@/context/connections_context";
import {selectAppSetting} from "@/data/preferences/select_app_setting";
import {setAppSetting} from "@/data/preferences/set_app_setting";

const DEFAULT_CPP = `#include <cudaq.h>

struct bell {
  auto operator()() __qpu__ {
    cudaq::qubit q0;
    cudaq::qubit q1;

    h(q0);
    x(q0);

    mz(q0);
    mz(q1);
  }
};

int main() {
  auto counts = cudaq::sample(bell{});
  counts.dump();
}
`;

export function NewJobEditor() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {connections} = useContext(ConnectionsContext);

  const [submitError, setSubmitError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [jobSource, setJobSource] = useState(DEFAULT_CPP);

  const session = useSelector(s => s.accessControlSession);

  const connection = Object.values(connections)[0]; // TODO
  const show = useSelector(selectAppSetting("jobs.showCudaQJobEditor"));
  const handleChange = (ev) => {
    setJobSource(ev.target.value);
  };

  const handleClickOut = () => {
    dispatch(setAppSetting("jobs.showCudaQJobEditor", false));
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    const sendingTimeout = setTimeout(
      () => setSubmitting(false),
      FAKE_SENDING_DURATION_MS
    );
    try {
      const job = await Aqumen.Job.specification(session, "simulate", {
        simulator: "nvidia",
        upload: {
          main: Aqumen.Job.fileObject({
            filePath: "./cq.cpp",
            raw: jobSource
          })
        }
      });
      await Aqumen.Job.dispatch(session, job, connection);
      dispatch(toggleAppSetting("jobs.showCudaQJobEditor"));

    } catch (e) {
      console.error(e);
      setSubmitError(e);

    } finally {
      clearTimeout(sendingTimeout);
      setSubmitting(false);
    }
  };

  const submitDisabled = submitting;

  let className = "new-job-container";
  let submitLabelId = "jobs.new.submit.button";
  if (!show) {
    className += " hidden";
  } else if (submitError) {
    className += " submit-error";
    submitLabelId += ".error";
  } else if (submitting) {
    className += " submitting";
    submitLabelId += ".submitting";
  }

  return (
    <div className={className}>
      <div className="new-job-backdrop">
        <div className="new-job-editor-area">
          <textarea
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            autoFocus={true}
            className="new-job-editor"
            onChange={handleChange}
            spellCheck="false"
            value={jobSource}
            wrap="off">
          </textarea>
          <button className="new-job-cancel" onClick={handleClickOut}>
            Close
          </button>
          <button className="new-job-submit" disabled={submitDisabled} onClick={handleSubmit}>
            {intl.formatMessage({id: submitLabelId})}
          </button>
        </div>

        {submitError && (
          <div className="error-display">
            <span className="error-id">{submitError.id}</span>
            <span className="error-message">{submitError.message}</span>
          </div>
        )}
      </div>
    </div>
  );
}
