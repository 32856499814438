import Aqumen from "@aqumen/sdk";
import React, {useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "@/constants.js";

export function TokenDeleteButton(props) {
  const intl = useIntl();
  const dispatch = useDispatch
  const token = props.token;

  const confirmTimeout = useRef(null);
  const [confirming, setConfirming] = useState(false);
  const session = useSelector(s => s.accessControlSession);

  const handleClickDelete = async () => {
    clearTimeout(confirmTimeout.current);
    if (!confirming) {
      setConfirming(true);
      confirmTimeout.current = setTimeout(
        () => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT_MS
      );
      return;
    }
    confirmTimeout.current = null;
    try {
      await Aqumen.Token.delete(session, token);
    } finally {
      setConfirming(false);
    }
  };

  let className = "token-delete-button token-action";
  let messageId = "tokens.token.delete.button";
  if (confirming) {
    className += " confirming";
    messageId += ".confirming";
  }

  return (
    <button className={className} onClick={handleClickDelete}>
      {intl.formatMessage({id: messageId})}
    </button>
  );
}
