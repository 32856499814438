import React from "react";

import {formatTimestamp} from "@/library/format/format_timestamp.js";


export function TimestampDisplay(props) {
  let formatted = formatTimestamp(props.value, props);
  let className = `timestamp-display ${props.className ?? ""}`
  if (!formatted) {
    className += " empty";
    formatted = props.empty;
  }

  return (
    <span className={className}>{formatted}</span>
  );
}
