import _ from "lodash";
import {createSlice} from "@reduxjs/toolkit";

export function createGlobalSlice(name, override = {}) {
  const slice = createSlice({
    name,
    initialState: {},
    reducers: {
      "merge": (state, action) => {
        return {
          ...state,
          ..._.keyBy(action.payload, "id")
        };
      },
      "remove": (state, action) => {
        return Object.keys(state).reduce((keep, id) => {
          if (!action.payload[id]) {
            keep[id] = state[id];
          }
          return keep;
        }, {});
      },
      "replace": (_state, action) => {
        return _.keyBy(action.payload, "id");
      },
      "update": (state, action) => {
        state[action.payload.id] = action.payload;
      }
    },
    extraReducers: (builder) => builder
      .addCase("sessions/deauthenticate", () => ({})),

    ...override
  });

  return {
    slice,
    reducer: slice.reducer,
    merge: slice.actions.merge,
    replace: slice.actions.replace,
    update: slice.actions.update
  };
}
