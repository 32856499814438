import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {useIntl} from "react-intl"

import {selectAppFeature} from "@/data/preferences/select_app_feature.js";

export function NavigationButton(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  if (props.authorize) {
    const selectPermissions = createSelector(
      s => s.accessControlSession?.user?.roles,
      roles => (roles || []).map(r => r.permissions).flat()
    );
    const selectAuthorizingPermissions = createSelector(
      selectPermissions,
      userPermissions => props.authorize.some(ap =>
        userPermissions.find(up => Object.keys(ap).every(k =>
          (Array.isArray(ap[k])) ? ap[k].some(v => up[k] === v) : up[k] === ap[k]
        ))
      )
    );
    const hasAuthorizingPermissions = useSelector(selectAuthorizingPermissions);
    if (!hasAuthorizingPermissions) {
      return null;
    }
  }

  const currentPath = useSelector(s => s.router.path);
  const showWIP = useSelector(selectAppFeature("wip.show"));
  if (props.wip && !showWIP) {
    return null;
  }

  const navigate = () => dispatch({type: "@@minimum-router/navigate", path: props.path});

  let className = `button navigation ${props.label} ${props.className}`;
  if (currentPath === props.path) {
    className += " current";
  }
  return (
    <button className={className} onClick={navigate}>
      {intl.formatMessage({id: "navigation.button." + props.label})}
    </button>
  );
}
