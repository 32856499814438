import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";
import {ONE_DAY_MS} from "@/constants.js";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";

export function OrganizationPrunePeriodInput(props) {
  const intl = useIntl();

  let days = Math.round(parseInt(props.organization.prunePeriod) / ONE_DAY_MS);
  if (isNaN(days) || !days) {
    days = "";
  }
  const onChange = (event) => {
    props.onChange({target: {value: parseInt(event.target.value) * ONE_DAY_MS}});
  };

  return (
    <label className="organization-prune-period-days">
      <span className="label-text">
        {intl.formatMessage({id: `organizations.${props.messageKey}.prunePeriodDays.label`})}
      </span>
      <input className="organization-prune-period-days"
        placeholder={intl.formatMessage({id: `organizations.${props.messageKey}.prunePeriodDays.placeholder`})}
        type="text" pattern="[0-9]*"
        disabled={props.disabled}
        onChange={onChange}
        onKeyUp={blurOnEnter}
        step="1"
        value={days}
      />
    </label>
  );
}
