import Aqumen from "@aqumen/sdk";
import {produce} from "immer";
import React, {useState} from "react";
import {useIntl} from "react-intl"
import {useDispatch, useSelector} from "react-redux";

import {CONFIRM_DESTRUCTIVE_TIMEOUT_MS} from "@/constants.js";
import {setAccessControlSession} from "@/data/access_control_session/access_control_session_slice.js";

export function AcceptAgreementButton(props) {
  const agreement = props.agreement;

  const dispatch = useDispatch();
  const intl = useIntl();

  const [confirm, setConfirm] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleAccept = async () => {
    if (confirm) {
      const accepted = await Aqumen.AcceptedAgreement.create(
        session, {agreement, acceptedAt: Date.now()}
      );
      setConfirm(false);

      const updatedSession = produce(session, draft => {
        draft.user.acceptedAgreements.push(accepted);
      });
      dispatch(setAccessControlSession(updatedSession));

      return;
    }

    setConfirm(true);
    setTimeout(() => setConfirm(false), CONFIRM_DESTRUCTIVE_TIMEOUT_MS);
  };

  let className = "agreement-action button accept";
  let messageId = `agreements.${agreement.identifier}.button.label.accept`;
  if (confirm) {
    className += " confirm";
    messageId += ".confirm";
  }

  return (
    <button className={className} onClick={handleAccept}>
      {intl.formatMessage({id: messageId})}
    </button>
  );
}
