import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";

import {DisplayId} from "@/library/display/display_id.jsx";

import {SimulatorActionsTile} from "./simulator_actions_tile.jsx";
import {SimulatorInputsTile} from "./simulator_inputs_tile.jsx";

export function SimulatorCard({simulator, session}) {
  const intl = useIntl();

  const [confirming, setConfirming] = useState(null);

  const validator = (event) => {
    if (["identifier", "publicKey"].includes(event.target.name)) {
      return !Aqumen.Utility.isBlank(event.target.value);
    }

    return true;
  };

  const handleChange = (event) => {
    let patch = null;

    if (event.target.name === "jobTermination") {
      if (event.target.checked) {
        if (!simulator.jobTermination.includes(event.target.value)) {
          patch = {
            jobTermination: simulator.jobTermination.concat([event.target.value])
          }
        }
      } else {
        if (simulator.jobTermination.includes(event.target.value)) {
          patch = {
            jobTermination: simulator.jobTermination.filter(t => t !== event.target.value)
          }
        }
      }
    } else {
      if (simulator[event.target.name] !== event.target.value) {
        if (validator(event)) {
          patch = {[event.target.name]: event.target.value};
        }
      }
    }

    if (patch) {
      return Aqumen.Simulator.update(
        simulator.session, {id: simulator.id}, patch
      );
    }
  }

  let className = "simulator-card card focusable-item";
  if (simulator.archivedAt) {
    className += " archived";
  }
  if (confirming) {
    className += " confirming";
  }

  return (
    <div className={className}>
      <SimulatorInputsTile
        confirming={confirming}
        simulator={simulator}
        onChange={handleChange}
        validator={validator}
      />

      <div className="simulator-card-meta">
        <div className="simulator-card-ids">
          <div className="simulator-card-aqumen-id">
            <span className="data-label">
              {intl.formatMessage({id: "operations.simulators.simulator.id.aqumen.label"})}
            </span>
            <span className="data-value">
              <DisplayId id={simulator.id} disabled={confirming}/>
            </span>
          </div>
        </div>
      </div>

      <SimulatorActionsTile
        confirming={confirming}
        simulator={simulator}
        session={session}
        setConfirming={setConfirming}
      />
    </div>
  );
}
