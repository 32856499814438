import React from "react";

import LogoQci from "assets/icons/logo_qci.svg";
import {SignInPasskeyTile} from "@/authentication/sign_in_passkey_tile.jsx";
import {useIntl} from "react-intl"

export function SignInView(props) {
  console.group("SignInView");
  const intl = useIntl()

  try {
    return (
      <div className={"authentication-view sign-in view " + (props.className ?? "")}>
        <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>

        <h1 className="title-sign-in title">
          {intl.formatMessage({id: "signIn.title"}, {elevated: (copy) => (<span className="elevated">{copy}</span>)})}
        </h1>

        <hr className="help-divider"/>

        <SignInPasskeyTile/>

        <hr className="help-divider"/>

        <p className="help">
          {
            intl.formatMessage({id: "signIn.help"}, {
              help: (copy) => (<p className="help-sign-in help">{copy}</p>),
              extra: (copy) => (<p className="help-sign-in extra">{copy}</p>),
              ios: (label) => (<a className="help-link-sign-in help-link" target="blank" href="https://support.apple.com/guide/iphone/use-passkeys-to-sign-in-to-apps-and-websites-iphf538ea8d0/ios">{label}</a>),
              macos: (label) => (<a className="help-link-sign-in help-link" target="blank" href="https://support.apple.com/guide/mac-help/create-a-passkey-mchl4af65d1a/mac">{label}</a>),
              google: (label) => (<a className="help-link-sign-in help-link" target="blank" href="https://safety.google/authentication/passkey">{label}</a>),
              microsoft: (label) => (<a className="help-link-sign-in help-link" target="blank" href="https://learn.microsoft.com/windows/security/identity-protection/passkeys">{label}</a>)
            })
          }
        </p>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
