import React from "react";
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from "redux-persist";
import {FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";

import {App} from "./app.jsx";
import {rootReducer} from "./root_reducer.js";

export function rootProvider(root, storage) {
  const logger = store => next => action => {
    const result = next(action)
    console.group("Redux Dispatch: " + action.type);
    console.debug("Action", action);
    console.debug("New State", store.getState());
    console.groupEnd();
    return result;
  };
  const persisted = persistReducer({key: "aqumen-data", storage}, rootReducer);
  const store = configureStore({
    reducer: persisted,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat([
        (logger)
      ])
  });

  const persistor = persistStore(store);

  return root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App/>
      </PersistGate>
    </Provider>
  );
}
