import localForage from "localforage";
import {IntlProvider} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {AgreementView} from "./agreements/agreement_view.jsx";
import {AppErrorBoundary} from "./app_error_boundary.jsx";
import {ConnectionsContext} from "./context/connections_context.js";
import {ensureConnections} from "./library/utility/ensure_connections.js";
import {NoEnvironmentAccessView} from "./authentication/no_environment_access_view.jsx";
import {RegisterPasskeyView} from "./authentication/register_passkey_view.jsx";
import {RootNavigation} from "./root_navigation.jsx";
import {selectConnected} from "./data/user_interface/select_connected.js";
import {selectEnvironmentSession} from "./data/environment_session/select_environment_session.js";
import {selectAppFeature} from "./data/preferences/select_app_feature.js";
import {selectNextUnacceptedAgreement} from "./data/agreement/select_next_unaccepted_agreement.js";
import {selectAppSetting} from "./data/preferences/select_app_setting.js";
import {SignInView} from "./authentication/sign_in_view.jsx";
import {syncToday} from "./library/utility/sync_today.js";

import {loadAllTypes} from "./data/load_all_types.js";
import {UpgradeView} from "./upgrade/upgrade_view.jsx";

import enUS from "./locales/en_us.json";

export function App() {
  const dispatch = useDispatch();

  const accessControlSession = useSelector(s => s.accessControlSession);
  const connected = useSelector(selectConnected());
  const lastToday = useSelector(s => s.today);
  const overrideColorSchemePreference = useSelector(selectAppSetting("overrideColorScheme"));
  const sessions = useSelector(selectEnvironmentSession());
  const unacceptedAgreement = useSelector(selectNextUnacceptedAgreement());
  const useColorSchemeOne = useSelector(selectAppFeature("theme.colorsOne"));

  const sameDayTimeout = useRef(null);
  const [connections, setConnections] = useState({});
  const [restartRequired, setRestartRequired] = useState(null);

  useEffect(() => {
    (async () => {
      if (process.env.APP_UPGRADE_RESTART !== "true") {
        setRestartRequired(false);
        return;
      }

      const dataVersion = await localForage.getItem("version:aqumen-data");
      if (dataVersion === process.env.APP_VERSION) {
        setRestartRequired(false);
        return;
      }

      // Once all end users have a data version, remove the persist inspections
      const persistedData = await localForage.getItem("persist:aqumen-data");
      if (!persistedData) {
        setRestartRequired(false);
        return;
      }

      if (JSON.parse(persistedData).user === "{}") {
        await localForage.removeItem("persist:aqumen-data");
        setRestartRequired(false);
        return;
      }

      setRestartRequired(true);
    })().catch(console.error);
  }, []);

  useEffect(() => {
    if (restartRequired === false) {
      ensureConnections({connections, dispatch, sessions, setConnections});
    }
  }, [sessions, restartRequired]);

  useEffect(() => {
    (async () => {
      if (restartRequired === false) {
        await localForage.setItem("version:aqumen-data", process.env.APP_VERSION);
        syncToday({dispatch, lastToday, sameDayTimeout});
        await loadAllTypes("merge", dispatch, sessions);
      }
    })().catch(console.error);
  }, [restartRequired]);

  const root = document.getElementsByTagName("html")[0];
  const overrideColorScheme = overrideColorSchemePreference
    || localStorage.getItem("preferences.qwa.overrideColorScheme");

  if (overrideColorScheme === "dark") {
    root.classList.remove("override-system-scheme-light");
    root.classList.add("override-system-scheme-dark");
  } else if (overrideColorScheme === "light") {
    root.classList.remove("override-system-scheme-dark");
    root.classList.add("override-system-scheme-light");
  } else {
    root.classList.remove("override-system-scheme-light");
    root.classList.remove("override-system-scheme-dark");
  }

  const colorScheme = (useColorSchemeOne) ? "colors-one" : "default";
  root.setAttribute("data-theme", colorScheme);

  const UA = navigator.userAgent;
  if (/Chrome\/\w+/.test(UA) && !/Edg.*\/\w+/.test(UA)) {
    root.classList.add("chrome");
  }
  if (/Win/.test(UA)) {
    root.classList.add("windows");
  }

  const authenticationOrNavigation = () => {
    const urlParams = new URLSearchParams(document.location.search);
    const registrationToken = urlParams.get("registration-token");
    if (registrationToken) {
      return <RegisterPasskeyView registrationToken={registrationToken}/>;
    }
    if (!accessControlSession?.user) {
      return <SignInView/>;
    }
    if (restartRequired === null) {
      return (<div></div>);
    }
    if (restartRequired) {
      return (<UpgradeView/>);
    }
    if (unacceptedAgreement) {
      return <AgreementView/>;
    }
    if (connected && Object.keys(sessions).length <= 0) {
      return <NoEnvironmentAccessView/>;
    }
    return <RootNavigation/>;
  }

  return (
    <div className="app">
      <AppErrorBoundary>
        <IntlProvider locale={"en-US"} messages={enUS}>
          <ConnectionsContext.Provider value={{connections, setConnections}}>
            {authenticationOrNavigation()}
          </ConnectionsContext.Provider>
        </IntlProvider>
      </AppErrorBoundary>
    </div>
  );
}
