import {sameDay} from "./same_day.js";
import {setToday} from "@/data/today/today_slice.js";

export function syncToday({dispatch, lastToday, sameDayTimeout}) {
  return () => {
    const x = () => {
      clearTimeout(sameDayTimeout.current);
      if (!sameDay(lastToday)) {
        dispatch(setToday());
      }
      sameDayTimeout.current = setTimeout(x, 5_000);
    };
    x();
  };
}
