import localForage from "localforage";
import React from "react";
import {useIntl} from "react-intl"

import {ConfirmableButton} from "@/library/controls/confirmable_button.jsx";
import LogoQci from "assets/icons/logo_qci.svg";

export function UpgradeView(props) {
  console.group("UpgradeView");
  const intl = useIntl();

  const handleRestartClick = async (event) => {
    if (event.confirmed) {
      await localForage.ready();
      await localForage.setDriver(localForage.INDEXEDDB);
      await localForage.removeItem("persist:aqumen-data");
      await localForage.setItem("version:aqumen-data", process.env.APP_VERSION);
      location.reload();
    }
  }

  try {
    return (
      <div className={"upgrade-view view" + (props.className ?? "")}>

        <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>

        <h1 className="title-upgrade title">
          {intl.formatMessage({id: "upgrade.title"}, {
            version: process.env.APP_VERSION,
            elevated: (copy) => (<span className="elevated">{copy}</span>)
          })}
        </h1>
        <hr className="help-divider"/>

        <h2 className="title title-upgrade-restart">
        {intl.formatMessage({id: "upgrade.restart.title"})}
        </h2>


        <hr className="help-divider"/>

        <p className="help help-upgrade-restart">
          {intl.formatMessage({id: "upgrade.restart.help"})}
        </p>

        <hr className="help-divider"/>

        <div className="actions upgrade-actions">
          <ConfirmableButton
            className="button upgrade-restart-button"
            messageId="upgrade.restart.button"
            onClick={handleRestartClick}
          />
        </div>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
