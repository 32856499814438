import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {ONE_DAY_MS} from "@/constants.js";
import {OrganizationIdentifierInput} from "./organization_identifier_input.jsx";
import {OrganizationPrunePeriodInput} from "./organization_prune_period_input.jsx";
import {setAppSetting} from "@/data/preferences/set_app_setting.js";

function emptyOrganization() {
  return {identifier: "", prunePeriod: 30 * ONE_DAY_MS};
}

const MIN_NEW_ORGANIZATION_PROPERTY_LENGTH = {
  identifier: 2
};

export function OrganizationNewTile(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [blanks, setBlanks] = useState({identifier: true});
  const [invalids, setInvalids] = useState({identifier: false});
  const [organization, setOrganization] = useState(emptyOrganization());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChangeOrganization = (property) => (ev) => {
    setOrganization(Object.assign({}, organization, {[property]: ev.target.value}));
    if (ev.target.value.length > 0
        && ev.target.value.trim().length < MIN_NEW_ORGANIZATION_PROPERTY_LENGTH[property]) {
      setInvalids({...invalids, [property]: true});
    } else if (Object.hasOwn(blanks, property) && Aqumen.Utility.isBlank(ev.target.value?.toString())) {
      setBlanks({...blanks, [property]: true});
      setInvalids({...invalids, [property]: false});
    } else {
      if (Object.hasOwn(blanks, property)) {
        setBlanks({...blanks, [property]: false});
      }
      setInvalids({...invalids, [property]: false});
    }
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      await Aqumen.Organization.create(session, Aqumen.Utility.create("Organization", session, {
        identifier: organization.identifier,
        prunePeriod: organization.prunePeriod
      }));
      setOrganization(emptyOrganization());
      dispatch(setAppSetting("organizations.showNewCardOnce", false));
    } finally {
      setWorking(false);
    }
  };

  const allDisabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "organization-card new focusable-item");
  if (working) {
    className += " working";
  }

  console.debug("ORGANIZATION_NEW_TILE", {blanks, invalids})
  return (
    <div className={className}>
      <div className="organization-card-inputs">
        <OrganizationIdentifierInput organization={organization}
          disabled={working}
          onChange={handleChangeOrganization("identifier")}
          messageKey="new"
          />
        <OrganizationPrunePeriodInput organization={organization}
          disabled={working}
          onChange={handleChangeOrganization("prunePeriod")}
          messageKey="new"
        />
      </div>

      <div className="organization-card-meta">
        <div className="organization-card-actions">
          <div className="organization-card-action">
            <button className="organization-create" disabled={allDisabled} onClick={handleCreate}>
              {intl.formatMessage({id: "organizations.new.create.button"})}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
