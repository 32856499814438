import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "@/library/utility/blur_on_enter.js";
import {DEBOUNCE_KEYSTROKES_MS} from "@/constants";

export function InputText({className, disabled, messageId, onChange, validator, value}) {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState(value ?? "");
  const [inputValid, setInputValid] = useState(true);

  const debouncedChange = useDebouncedCallback(onChange, DEBOUNCE_KEYSTROKES_MS);

  const handleChange = (event) => {
    if (event.target.value !== value) {
      setInputValue(event.target.value);
      if (validator) {
        setInputValid(validator(event));
      }
      debouncedChange(event);
    }
  };

  const handleBlur = (event) => {
    debouncedChange.cancel();
    if (event.target.value !== value) {
      return onChange(event);
    }
  };

  let currentClassName = className;
  if (_.isEmpty(inputValue)) {
    currentClassName += " blank";
  }
  if (!inputValid) {
    currentClassName += " invalid";
  }

  return (
    <label className={currentClassName}>
      <span className="label-text">
        {intl.formatMessage({id: messageId})}
      </span>
      <input className={`input-text ${className}`}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyUp={blurOnEnter}
        type="text"
        value={inputValue}
      />
    </label>
  );
}
