import React from "react";

import {ArchiveButton} from "@/library/controls/archive_button.jsx";

export function SimulatorActionsTile({simulator, confirming, setConfirming}) {
  return (
    <div className="simulator-card-actions-tile">
      <ArchiveButton className="action-button simulator-action-button"
        entity={simulator}
        confirming={confirming}
        messageId="operations.simulators.simulator.actions.archive"
        session={simulator.session}
        setConfirming={setConfirming}
      />
    </div>
  );
}
