import React from "react";
import {useIntl} from "react-intl";

import {ALL_APP_FEATURES} from "@/data/preferences/preferences_slice.js";
import {AccountSettingCheckbox} from "./account_setting_checkbox.jsx";


export function AccountFeaturesPanel(props) {
  const intl = useIntl();

  return (
    <div className="account-settings-panel features">
      <div className="account-settings-panel-title">
        {intl.formatMessage({id: "account.settings.features.title"})}
      </div>
      {Object.keys(ALL_APP_FEATURES).map(p => (
        <AccountSettingCheckbox key={p} setting={p}/>
      ))}
    </div>
  );
}
