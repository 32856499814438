import _ from "lodash";

export function payload(action, session, payload) {
  if (_.isEmpty(payload)) {
    return {payload: "noop", type: "noop"};
  }
  return {
    type: `${payload[0].__typename}/${action}`,
    session,
    payload: payload.map(p => Object.assign({}, p, {session}))
  };
};
