import {Query} from "./query.js";
import {loadOneType} from "./load_one_type.js";
import {unloadArchived} from "./unload_archived.js";

export function loadAndUnloadArchivable(entityName, dispatch, sessions, includeArchived) {
  return () => {
    const queryType = Query[entityName].type;
    const unload = () => unloadArchived(entityName, queryType, dispatch, sessions);
    if (includeArchived) {
      loadOneType(entityName, "merge", dispatch, sessions, {
        [queryType]: {includeArchived}
      });
    } else {
      unload();
    }

    return () => (includeArchived) ? unload() : null;
  };
}
