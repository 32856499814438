import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";

export function JobTimestampDisplay(props) {
  const intl = useIntl();
  const forceSeconds = props.forceSeconds || useSelector(selectAppSetting("jobs.timestamps.seconds"));
  const empty = (props.messageEmptyId) ? intl.formatMessage({id: props.messageEmptyId}) : "";

  return (
    <div className={"job-timestamp-display " + (props.className ?? "")}>
      <span className="data-label">
        {props.label ?? intl.formatMessage({id: props.messageId})}
      </span>
      <TimestampDisplay {...{omitDate: props.omitDate, forceSeconds, empty, value: props.value}}/>
    </div>
  );
};
