// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pane.configurations-pane .configuration-card-identifier, .pane.configurations-pane .configuration-card-value {
  font-family: ui-monospace;
  font-size: 0.9rem;
  white-space: nowrap;
}
.pane.configurations-pane .instance-name, .pane.configurations-pane .instance-public-key {
  display: block;
  margin-left: 1.5rem;
}
.pane.configurations-pane .instance-name {
  margin-top: 1rem;
}
.pane.configurations-pane .instance-name .name {
  color: var(--color-elevated-text);
  font-weight: bold;
}
.pane.configurations-pane .instance-public-key .key {
  color: var(--color-elevated-text);
  font-family: ui-monospace;
  font-size: 1.1rem;
}
.pane.configurations-pane .configuration-table {
  border-collapse: collapse;
  margin: 1rem;
}
.pane.configurations-pane .configuration-table caption {
  color: var(--color-base-text);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  text-align: left;
}
.pane.configurations-pane .configuration-table tr:nth-child(odd) td {
  background-color: var(--color-selected-canvas);
}
.pane.configurations-pane .configuration-table td, .pane.configurations-pane .configuration-table th {
  border: var(--border-elevated);
  padding: 0.5rem 1rem;
  vertical-align: top;
}
.pane.configurations-pane .configuration-table .configuration-card-description {
  min-width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/style/panes/configurations.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,cAAA;EACA,mBAAA;AADJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,iCAAA;EACA,iBAAA;AAFJ;AAKE;EACE,iCAAA;EACA,yBAAA;EACA,iBAAA;AAHJ;AAME;EACE,yBAAA;EACA,YAAA;AAJJ;AAMI;EACE,6BAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;AAJN;AAMI;EACE,8CAAA;AAJN;AAOI;EACE,8BAAA;EACA,oBAAA;EACA,mBAAA;AALN;AAQI;EACE,gBAAA;AANN","sourcesContent":[".pane.configurations-pane {\n  .configuration-card-identifier, .configuration-card-value {\n    font-family: ui-monospace;\n    font-size: 0.9rem;\n    white-space: nowrap;\n  }\n\n  .instance-name, .instance-public-key {\n    display: block;\n    margin-left: 1.5rem;\n  }\n  .instance-name {\n    margin-top: 1rem;\n  }\n\n  .instance-name .name {\n    color: var(--color-elevated-text);\n    font-weight: bold;\n  }\n\n  .instance-public-key .key {\n    color: var(--color-elevated-text);\n    font-family: ui-monospace;\n    font-size: 1.1rem;\n  }\n\n  .configuration-table {\n    border-collapse: collapse;\n    margin: 1rem;\n\n    caption {\n      color: var(--color-base-text);\n      font-size: 1.1rem;\n      margin-bottom: 0.5rem;\n      text-align: left;\n    }\n    tr:nth-child(odd) td {\n      background-color: var(--color-selected-canvas);\n    }\n\n    td, th {\n      border: var(--border-elevated);\n      padding: 0.5rem 1rem;\n      vertical-align: top;\n    }\n\n    .configuration-card-description {\n      min-width: 20rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
