// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-size: 0.9rem;
  --font-size-card-title: 1rem;
  --font-size-input-help: 0.8rem;
}

body {
  font-family: system-ui;
  font-size: 0.9rem;
}

h1 {
  font-weight: normal;
  font-size: 1.4rem;
}

h2 {
  font-weight: normal;
  font-size: 1rem;
}

.clock, .uuid {
  font-family: ui-monospace;
  font-size: 0.9rem;
}

.windows .clock, .windows .uuid, .chrome .clock, .chrome .uuid {
  font-family: ui-monospace, monospace;
}

.pane-title {
  font-weight: normal;
  font-size: 1.4rem;
}`, "",{"version":3,"sources":["webpack://./src/style/typography.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,4BAAA;EACA,8BAAA;AACF;;AAEA;EACE,sBAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;AACF;;AAGE;EACE,oCAAA;AAAJ;;AAIA;EACE,mBAAA;EACA,iBAAA;AADF","sourcesContent":[":root {\n  --font-size: 0.9rem;\n  --font-size-card-title: 1rem;\n  --font-size-input-help: 0.8rem;\n}\n\nbody {\n  font-family: system-ui;\n  font-size: 0.9rem;\n}\n\nh1 {\n  font-weight: normal;\n  font-size: 1.4rem;\n}\n\nh2 {\n  font-weight: normal;\n  font-size: 1rem;\n}\n\n.clock, .uuid {\n  font-family: ui-monospace;\n  font-size: 0.9rem;\n}\n\n.windows, .chrome {\n  .clock, .uuid {\n    font-family: ui-monospace, monospace;\n  }\n}\n\n.pane-title {\n  font-weight: normal;\n  font-size: 1.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
