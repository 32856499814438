import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

import {DisplayId} from "@/library/display/display_id.jsx";

export function JobIdsTile(props) {
  const job = props.job;
  const intl = useIntl();

  const jobSource = job.source ?? "aqumen";
  const externalId = job.sourceExtendedId ?? job.sourceId;

  return (
    <div className="job-ids-tile">
      <div className="job-aqumen-id">
        <span className="data-label">
          {intl.formatMessage({id: "jobs.job.id.aqumen.label"})}
        </span>
        <span className="data-value">
          <DisplayId id={job.id}/>
        </span>
      </div>
      {Boolean(externalId) && (
        <div className="job-external-id">
          <span className="data-label">
            {intl.formatMessage({id: `jobs.job.id.${jobSource}.label`})}
          </span>
          <span className="data-value">
            <DisplayId id={externalId}/>
          </span>
        </div>
      )}
    </div>
  );
}
