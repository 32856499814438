import {createSelector} from "@reduxjs/toolkit";

export function selectNextUnacceptedAgreement() {
  return createSelector(
    s => s.agreement,
    s => s.accessControlSession?.user?.acceptedAgreements,
    (all, accepted) => {
      if (!all) {
        return null;
      }
      return Object.values(all).find(
        aa => !aa.archivedAt && (!accepted || !accepted.find(ua => ua.agreement.id === aa.id))
      );
    }
  );
}
