import React, {useState} from "react";
import {Routes, Route} from "@minimum/router"
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {About} from "./about.jsx";
import {AccessControlPane} from "./panes/access_control/access_control_pane.jsx";
import {AccountPane} from "./panes/account/account_pane.jsx";
import {AccountPasskeysPane} from "./panes/account_passkeys/account_passkeys_pane.jsx";
import {AccountReservationsPane} from "./panes/account_reservations/account_reservations_pane.jsx";
import {ComingSoonPane} from "./coming_soon_pane.jsx";
import {CompilersPane} from "./panes/operations/compilers/compilers_pane.jsx";
import {ConfigurationsPane} from "./panes/operations/configurations_pane.jsx";
import {ControlServersPane} from "./panes/operations/control_servers/control_servers_pane.jsx";
import {DashboardPane} from "./panes/dashboard/dashboard_pane.jsx";
import {JobsPane} from "./panes/jobs/jobs_pane.jsx";
import {NavigationErrorBoundary} from "./navigation_error_boundary.jsx";
import {NavigationMenu} from "./navigation/navigation_menu.jsx";
import {OrganizationsPane} from "./panes/organizations/organizations_pane.jsx";
import {PermissionsPane} from "./panes/permissions/permissions_pane.jsx";
import {RolesPane} from "./panes/roles/roles_pane.jsx";
import {setUserInterface} from "./data/user_interface/user_interface_slice.js";
import {SimulatorsPane} from "./panes/operations/simulators/simulators_pane.jsx";
import {SystemsPane} from "./panes/systems/systems_pane.jsx";
import {TokensPane} from "./panes/tokens/tokens_pane.jsx";
import {UsersPane} from "./panes/users/users_pane.jsx";
import LogoQci from "assets/icons/logo_qci.svg";

export function RootNavigation() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aboutOpen = useSelector(s => s.userInterface.aboutOpen);
  const navigationMenuOpen = useSelector(s => s.userInterface.navigationMenuOpen);

  const [menuClosing, setMenuClosing] = useState(false);
  const [menuOpening, setMenuOpening] = useState(false);
  const [animate, setAnimate] = useState(false);

  const toggleMenuOpen = () => {
    setAnimate(true);
    if (navigationMenuOpen) {
      setTimeout(() => dispatch(setUserInterface({"navigationMenuOpen": !navigationMenuOpen})), 250);
      setTimeout(() => setMenuClosing(false), 250);
      setMenuClosing(true);
    } else {
      dispatch(setUserInterface({"navigationMenuOpen": !navigationMenuOpen}));
      setTimeout(() => setMenuOpening(false), 250);
      setMenuOpening(true);
    }
  };

  const handleProductClick = () => {
    if (!navigationMenuOpen) {
      dispatch(setUserInterface({"aboutOpen": !aboutOpen}))
    }
  };

  let className = "root-navigation";
  if (navigationMenuOpen) {
    className += " navigation-menu-open";
  } else {
    className += " navigation-menu-closed";
  }

  if (menuOpening) {
    className += " navigation-menu-opening";
  } else if (menuClosing) {
    className += " navigation-menu-closing";
  }

  if (animate) {
    className += " animate";
  }

  return (
    <div className={className}>
      {aboutOpen && (<About/>)}

      <NavigationMenu toggleMenuOpen={toggleMenuOpen}/>

      <div className="main-content">
        <button className="product" onClick={handleProductClick}>
          Aqumen
        </button>
        <a className="" href="https://quantumcircuits.com" target="blank">
          <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>
        </a>
        <Routes errorBoundary={NavigationErrorBoundary}>
          <Route path="/access-control" element={<AccessControlPane/>}/>
          <Route path="/account" element={<AccountPane/>}/>
          <Route path="/administration" element={<ComingSoonPane label="administration"/>}/>
          <Route path="/billing" element={<ComingSoonPane label="billing"/>}/>
          <Route path="/compilers" element={<CompilersPane/>}/>
          <Route path="/configuration" element={<ConfigurationsPane/>}/>
          <Route path="/control-servers" element={<ControlServersPane/>}/>
          <Route path="/dashboard" element={<DashboardPane/>}/>
          <Route path="/history" element={<ComingSoonPane label="history"/>}/>
          <Route path="/jobs" element={<JobsPane/>}/>
          <Route path="/operations" element={<ComingSoonPane label="operations"/>}/>
          <Route path="/organizations" element={<OrganizationsPane/>}/>
          <Route path="/passkeys" element={<AccountPasskeysPane label="passkeys"/>}/>
          <Route path="/permissions" element={<PermissionsPane/>}/>
          <Route path="/report-usage" element={<ComingSoonPane label="reportUsage"/>}/>
          <Route path="/reports" element={<ComingSoonPane label="reports"/>}/>
          <Route path="/reservations-administer" element={<ComingSoonPane label="reservationAdministration" today={true}/>}/>
          <Route path="/reservations-self" element={<AccountReservationsPane/>}/>
          <Route path="/roles" element={<RolesPane/>}/>
          <Route path="/simulators" element={<SimulatorsPane/>}/>
          <Route path="/systems" element={<SystemsPane/>}/>
          <Route path="/tokens" element={<TokensPane/>}/>
          <Route path="/users" element={<UsersPane/>}/>
          <Route path="*" element={<JobsPane/>}/>
        </Routes>
      </div>

    </div>
  );
}
