import "./boilerplate/main.css";
import "./boilerplate/normalize.css";
import "./html.scss";

import "./color_palette.scss";
import "./color_foundation.scss";
import "./color_scheme.scss";

import "./common.scss";
import "./keyframes.scss";
import "./layout.scss";
import "./typography.scss";

import "./errors.scss";
import "./forms.scss";
import "./navigation_animation.scss";
import "./navigation_menu.scss";
import "./settings_tile.scss";

import "./views/agreements.scss";
import "./views/authentication.scss";
import "./views/tabbed.scss";
import "./views/upgrade.scss";

import "./panes/about.scss";
import "./panes/access_control.scss";
import "./panes/account_passkeys.scss";
import "./panes/account_reservations.scss";
import "./panes/account.scss";
import "./panes/coming_soon.scss";
import "./panes/compilers.scss";
import "./panes/simulators.scss";
import "./panes/configurations.scss";
import "./panes/control_servers.scss";
import "./panes/dashboard.scss";
import "./panes/jobs.scss";
import "./panes/organizations.scss";
import "./panes/permissions.scss";
import "./panes/roles.scss";
import "./panes/systems.scss";
import "./panes/tokens.scss";
import "./panes/users.scss";
