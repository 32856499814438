import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";

import {DisplayId} from "@/library/display/display_id.jsx";

import {ControlServerActionsTile} from "./control_server_actions_tile.jsx";
import {ControlServerInputsTile} from "./control_server_inputs_tile.jsx";

export function ControlServerCard({controlServer, session}) {
  const intl = useIntl();

  const [confirming, setConfirming] = useState(null);
console.debug("CONFIRMING", confirming);
  const validator = (event) => {
    if (["identifier", "publicKey"].includes(event.target.name)) {
      return !Aqumen.Utility.isBlank(event.target.value);
    }

    return true;
  };

  const handleChange = (event) => {
    let patch = null;
    console.debug("handleChange", {name: event.target.name, value: event.target.value, checked: event.target.checked});

    if (event.target.name === "jobTermination") {
      if (event.target.checked) {
        if (!controlServer.jobTermination.includes(event.target.value)) {
          patch = {
            jobTermination: controlServer.jobTermination.concat([event.target.value])
          }
        }
      } else {
        if (controlServer.jobTermination.includes(event.target.value)) {
          patch = {
            jobTermination: controlServer.jobTermination.filter(t => t !== event.target.value)
          }
        }
      }
    } else {
      if (controlServer[event.target.name] !== event.target.value) {
        if (validator(event)) {
          patch = {[event.target.name]: event.target.value};
        }
      }
    }

    if (patch) {
      return Aqumen.ControlServer.update(
        controlServer.session, {id: controlServer.id}, patch
      );
    }
  }

  let className = "control-server-card card focusable-item";
  if (controlServer.archivedAt) {
    className += " archived";
  }
  if (confirming) {
    className += " confirming";
  }

  return (
    <div className={className}>
      <ControlServerInputsTile
        confirming={confirming}
        controlServer={controlServer}
        onChange={handleChange}
        validator={validator}
      />

      <div className="control-server-card-meta">
        <div className="control-server-card-ids">
          <div className="control-server-card-aqumen-id">
            <span className="data-label">
              {intl.formatMessage({id: "operations.controlServers.controlServer.id.aqumen.label"})}
            </span>
            <span className="data-value">
              <DisplayId id={controlServer.id} disabled={confirming}/>
            </span>
          </div>
        </div>

        {controlServer.lockedAt && !controlServer.archivedAt && (
          <div className="control-server-locked">
            Locked
          </div>
        )}
      </div>

      <ControlServerActionsTile
        confirming={confirming}
        controlServer={controlServer}
        session={session}
        setConfirming={setConfirming}
      />
    </div>
  );
}
