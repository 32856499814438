export const ONE_SECOND_MS = 1_000;
export const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;
export const ONE_WEEK_MS = 7 * ONE_DAY_MS;
export const ONE_MONTH_MS = 30 * ONE_WEEK_MS;
export const ONE_YEAR_MS = 365 * ONE_MONTH_MS;

export const CONFIRM_DESTRUCTIVE_TIMEOUT_MS = 5 * ONE_SECOND_MS;
export const CONFIRM_INSTRUCTIONS_TIMEOUT_MS = 15 * ONE_SECOND_MS;

export const SERVICE_CREATE_VISUAL_TIMEOUT_MS = 20 * ONE_SECOND_MS;
export const SERVICE_UPDATE_VISUAL_TIMEOUT_MS = 20 * ONE_SECOND_MS;

export const DEBOUNCE_KEYSTROKES_MS = ONE_SECOND_MS;
export const DISPLAY_SUCCESS_TIMEOUT_MS = ONE_SECOND_MS;
export const ERROR_VISUAL_TIMEOUT_MS = 15 * ONE_SECOND_MS;
export const FAKE_SENDING_DURATION_MS = 5 * ONE_SECOND_MS;
