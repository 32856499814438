import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {TokenCard} from "./token_card.jsx";
import {TokenNewCard} from "./token_new_card.jsx";
import {reloadCurrentUser} from "@/data/user/reload_current_user.js"
import IconCopied from "assets/icons/icon_copied.svg";
import IconCopy from "assets/icons/icon_copy.svg";
import {DISPLAY_SUCCESS_TIMEOUT_MS} from "@/constants.js";

export function TokensPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [createdToken, setCreatedToken] = useState(null);

  const session = useSelector(s => s.accessControlSession);
  const selectCurrentUser = createSelector(
    s => s.user, s => s.accessControlSession, (au, acs) => au[acs.user.id]
  );
  const selectAllTokens = createSelector(
    selectCurrentUser, u => u?.tokens ?? []
  );
  const selectVisibleTokens = createSelector(
    selectAllTokens, at => at.filter(t => t.type === "user")
  );
  const selectOrderedTokens = createSelector(
    selectVisibleTokens,
    vt => vt.toSorted((a, b) => a.createdAt ?? a.receivedAt - b.createdAt ?? b.receivedAt)
  );
  const tokens = useSelector(selectOrderedTokens);

  const handleCreate = (created) => setCreatedToken(created.token);
  const handleCreatedClose = () => setCreatedToken(null);

  const [copiedToken, setCopiedToken] = useState(false);
  const copiedTokenTimeout = useRef(null);

  const handleCreatedCopy = () => {
    clearTimeout(copiedTokenTimeout.current);
    navigator.clipboard.writeText(createdToken);
    setCopiedToken(true);
    copiedTokenTimeout.current = setTimeout(
      () => setCopiedToken(false), DISPLAY_SUCCESS_TIMEOUT_MS
    );
  };

  const reload = () => reloadCurrentUser(dispatch, session);
  useEffect(() => {reload();}, []);

  return (
    <div className="tokens-pane pane">
      <div className="pane-top">
        <h1 className="tokens-title pane-title">
          {intl.formatMessage({id: "tokens.title"})}
        </h1>
      </div>

      {createdToken && (
        <div className="created-token-backdrop">
          <div className="created-token">
            <div className="created-token-title">
              {intl.formatMessage({id: "tokens.new.created.title"})}
            </div>

            <div className="created-token-message">
              {intl.formatMessage({id: "tokens.new.created.message"}, {em: (c) => (<em>{c}</em>)})}
            </div>

            <textarea className="created-token-value" readOnly={true}>
              {createdToken}
            </textarea>

            <div className="created-token-actions">
              <button className="created-token-close" onClick={handleCreatedClose}>
                {intl.formatMessage({id: "tokens.new.created.close.button"})}
              </button>

              <button className="created-token-copy" onClick={handleCreatedCopy}>
                {!copiedToken && (
                  <span>
                    <IconCopy aria-label={intl.formatMessage({id: "aria.label.icon.copy"})}/>
                    Copy
                  </span>
                )}

                {copiedToken && (
                  <span>
                    <IconCopied aria-label={intl.formatMessage({id: "aria.label.icon.copied"})}/>
                    Copied
                  </span>
                )}

              </button>
            </div>
          </div>
        </div>
      )}

      <div className="tokens-list list">
        <TokenNewCard reload={reload} onCreate={handleCreate}/>
        {tokens.map(t => <TokenCard key={t.id} token={t} reload={reload}/>)}
      </div>
    </div>
  );
}
