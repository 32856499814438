import React from "react";
import {useIntl} from "react-intl";

import {CalibrationIdentifierDisplay} from "./calibration_identifier_display.jsx";
import {PercentDisplay} from "@/library/display/percent_display.jsx";
import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";


export function QubitsDisplay(props) {
  const intl = useIntl();

  return (
    <table className={"calibration-table table qubits " + (props.className ?? "")}>
      <thead>
        <tr>
          <th className="component">
            {intl.formatMessage({id: "systems.calibration.qubits.column.identifier"})}
          </th>
          <th className="epg">
            {intl.formatMessage({id: "systems.calibration.qubits.column.xEpg"})}
          </th>
          <th className="epg">
            {intl.formatMessage({id: "systems.calibration.qubits.column.xo2Epg"})}
          </th>
          <th className="timestamp">
            {intl.formatMessage({id: "systems.calibration.qubits.column.calibratedAt"})}
          </th>
        </tr>
      </thead>
      <tbody>
        {props.qubits.map((qubit) => (
          <tr className="calibration-row row" key={props.calibration.id + qubit.identifier}>
            <td className="component"><CalibrationIdentifierDisplay value={qubit.identifier}/></td>
            <td className="epg"><PercentDisplay value={qubit.xEpg} scale={1}/></td>
            <td className="epg"><PercentDisplay value={qubit.xo2Epg} scale={1}/></td>
            <td className="timestamp"><TimestampDisplay value={qubit.calibratedAt}/></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
