import {createSelector} from "@reduxjs/toolkit";

import {selectSessionPermissions} from "@/data/permission/select_session_permissions";

export const selectSessionPermission = (target, action, extra) => createSelector(
  selectSessionPermissions,
  up => up.find(
    p => target === p.target
    && action === p.action
    && (extra === undefined || extra(p))
  )
);
