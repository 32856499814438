export function jobLastStatusPair(job) {
  switch (job.exit ?? job.status) {
    case "normal": return ["completed", job.endedAt];
    case "interrupted":
    case "terminated": return ["cancelled", job.exitedAt];
    case "queued":
    case "blocked":
    case "preparing":
    case "scheduled":
    case "received": return (job.estimatedStartAt) ? ["estimatedStart", job.estimatedStartAt] : ["received", job.receivedAt];
    case "executing":
    case "completing": return ["started", job.startedAt];
    case "expired": return ["expired", job.expiredAt];
    default: return ["error", job.exitedAt];
  }
}
