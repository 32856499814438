import Aqumen from "@aqumen/sdk";
import React from "react";

import {ConfirmableButton} from "./confirmable_button.jsx";

const ACTION_NAME = "toggleArchive";

export function ArchiveButton({
    className = "", confirming, disabled, entity, messageId, session,
    onClick = () => {},
    setConfirming = () => {}
  }) {

  const handleClick = async (event) => {
    if (event.confirmed) {
      const archivedAt = (entity.archivedAt === null) ? Date.now() : null;
      const updateCall = Aqumen[entity.__typename].update(
        session, {id: entity.id}, {archivedAt}
      );

      await onClick(event);
      return updateCall;
    }

    await onClick(event);
  };

  let currentClassName = `archive-button ${className}`;
  const currentDisabled = disabled || (confirming && confirming !== ACTION_NAME);
  let currentMessageId = messageId;

  if (entity.archivedAt) {
    currentClassName += " archived";
    currentMessageId += ".archived";
  }

  return (
    <ConfirmableButton
        className={currentClassName}
        confirming={confirming === ACTION_NAME}
        disabled={currentDisabled}
        messageId={currentMessageId}
        onClick={handleClick}
        setConfirming={(on) => setConfirming((on) ? ACTION_NAME : null)}
    />
  );
}
