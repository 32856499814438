import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {jobLastStatusPair} from "./job_last_status_pair.js";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";
import {TimestampDisplay} from "@/library/display/timestamp_display.jsx";

export function JobLastStatusDisplay(props) {
  const job = props.job;
  const intl = useIntl();

  const forceSeconds = useSelector(selectAppSetting("jobs.timestamps.seconds"));

  const [status, timestamp] = jobLastStatusPair(job);

  const className = `job-last-status-display ${_.kebabCase(job.status)} ${props.className ?? ""}`;

  return (
    <div className={className}>
      <span className="data-label">
        {intl.formatMessage({id: `jobs.job.lastStatus.${status}`})}
      </span>
      <span className="data-value">
        <TimestampDisplay className="job-last-status-change" forceSeconds={forceSeconds} label={status} value={timestamp}/>
      </span>
    </div>
  );
}
